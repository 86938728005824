import { AxiosResponse } from 'axios';
import router from '@/router';
import { ErrorRoutes } from '@/router/routes/error';
import { LoginRoutes } from '@/router/routes/login';
import { impl } from '@/utils/impl';

export enum ResponseStatus {
    Success = 'Success',
    NotFound = 'NotFound',
    Error = 'Error',
    NotAuthenticated = 'NotAuthenticated',
    Cancel = 'Cancel'
}

export type IsError = ResponseStatus.NotFound | ResponseStatus.Error | ResponseStatus.NotAuthenticated | ResponseStatus.Cancel;

export type ApiResponse<T> = [T | IsError, AxiosResponse | null];

export function isError<R> ([result, _]: ApiResponse<R>): boolean {
  return result === ResponseStatus.NotFound ||
        result === ResponseStatus.Error ||
        result === ResponseStatus.NotAuthenticated ||
        result === ResponseStatus.Cancel;
}

export interface IStatusHandler<R = ResponseStatus.Success> {
    onNotFound: (response: AxiosResponse | null) => Promise<void>;
    onError: (response: AxiosResponse | null) => Promise<void>;
    onNotAuthenticated: (response: AxiosResponse | null) => Promise<void>;
    onCancel: (response: AxiosResponse | null) => Promise<void>;
    onSuccess: (value: R) => Promise<void>;
}

export const DefaultIStatusHandler: IStatusHandler<any> = {
  onNotFound: async (_) => { router.push({ name: ErrorRoutes.notFound }); },
  onError: async (_) => { router.push({ name: ErrorRoutes.error }); },
  onNotAuthenticated: async (_) => { router.push({ name: LoginRoutes.login }); },
  onCancel: async (_) => { router.push({ name: ErrorRoutes.error }); },
  onSuccess: async (_) => { /* Do Nothing */ }
};

export function chainHandlers<R> (first?: Partial<IStatusHandler<R>>, second?: Partial<IStatusHandler<R>>): Partial<IStatusHandler<R>> {
  const result = impl<Partial<IStatusHandler<R>>>({});
  if (first?.onNotFound || second?.onNotFound) {
    result.onNotFound = async (response) => {
      if (first?.onNotFound) { await first.onNotFound(response); }
      if (second?.onNotFound) { await second.onNotFound(response); }
    };
  }
  if (first?.onNotAuthenticated || second?.onNotAuthenticated) {
    result.onNotAuthenticated = async (response) => {
      if (first?.onNotAuthenticated) { await first.onNotAuthenticated(response); }
      if (second?.onNotAuthenticated) { await second.onNotAuthenticated(response); }
    };
  }
  if (first?.onError || second?.onError) {
    result.onError = async (response) => {
      if (first?.onError) { await first.onError(response); }
      if (second?.onError) { await second.onError(response); }
    };
  }
  if (first?.onCancel || second?.onCancel) {
    result.onCancel = async (response) => {
      if (first?.onCancel) { await first.onCancel(response); }
      if (second?.onCancel) { await second.onCancel(response); }
    };
  }
  if (first?.onSuccess || second?.onSuccess) {
    result.onSuccess = async (response) => {
      if (first?.onSuccess) { await first.onSuccess(response); }
      if (second?.onSuccess) { await second.onSuccess(response); }
    };
  }
  return result;
}

/**
 * handle the response status if it is an error
 * and route the user to the respective error page.
 * @param status the response status
 * @returns true if status was error.  false otherwise.
 */
export async function handleErrorStatus<R> (
  [result, response]: ApiResponse<R>,
  customHandler?: Partial<IStatusHandler<R>>
): Promise<boolean> {
  const handler: IStatusHandler<R> = {
    onNotFound: customHandler?.onNotFound ??
            DefaultIStatusHandler.onNotFound,
    onError: customHandler?.onError ??
            DefaultIStatusHandler.onError,
    onNotAuthenticated: customHandler?.onNotAuthenticated ??
            DefaultIStatusHandler.onNotAuthenticated,
    onCancel: customHandler?.onCancel ??
            DefaultIStatusHandler.onCancel,
    onSuccess: customHandler?.onSuccess ??
            DefaultIStatusHandler.onSuccess
  };
  switch (result) {
    case ResponseStatus.NotFound:
      await handler.onNotFound(response);
      return true;
    case ResponseStatus.Error:
      await handler.onError(response);
      return true;
    case ResponseStatus.NotAuthenticated:
      await handler.onNotAuthenticated(response);
      return true;
    case ResponseStatus.Cancel:
      await handler.onCancel(response);
      return true;
    default:
      await handler.onSuccess(result);
      return false;
  }
}

export async function mapResultStatus<R, D extends IsError | R> (
  [result, response]: ApiResponse<D>,
  valueMapping: (result: D) => Promise<R>
): Promise<ApiResponse<R>> {
  switch (result) {
    case ResponseStatus.NotFound:
    case ResponseStatus.Error:
    case ResponseStatus.NotAuthenticated:
    case ResponseStatus.Cancel:
      return [result, response];
  }
  return [await valueMapping(result), response];
}

export async function handleErrorStatusResult<D> (
  apiResponse: ApiResponse<D>,
  customHandler?: Partial<IStatusHandler<D>>
): Promise<D | undefined> {
  const [result] = apiResponse;
  const isError = await handleErrorStatus(apiResponse, customHandler);
  if (isError) {
    return undefined;
  } else {
    return result as D;
  }
}

export async function handleErrorStatusResults<R> (
  statuses: Array<ApiResponse<R>>,
  customHandler?: Partial<IStatusHandler<R>>
): Promise<Array<R | undefined>> {
  return statuses.reduce(
    async (accumulator, current) => {
      const acc = await accumulator;
      const hasUndef = !!acc.find((v) => v === undefined);
      if (hasUndef) {
        return [...acc.map(() => undefined), undefined];
      } else {
        const nextResult = await handleErrorStatusResult(current, customHandler);
        return [...acc, nextResult];
      }
    },
    Promise.resolve([] as Array<R | undefined>)
  );
}

/**
 * If the first status is error, return the error. Otherwise, execute and return the nextStatus.
 * @param status first status
 * @param nextStatus next status function
 * @return next status or error.
 */
export async function chainStatusResults<P, D extends IsError | P> (
  apiResponse: Promise<ApiResponse<D>>,
  nextStatus: (previousStatus: D) => Promise<ApiResponse<P>>
): Promise<ApiResponse<P>> {
  const [result, response] = await apiResponse;
  switch (result) {
    case ResponseStatus.NotFound:
    case ResponseStatus.Error:
    case ResponseStatus.NotAuthenticated:
    case ResponseStatus.Cancel:
      return [result, response];
  }
  return await nextStatus(result);
}
