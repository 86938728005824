<template>
  <div id="app">
    <div>
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App'
});
</script>

<style lang="scss">
@import '@/styles/color-classes.scss';
@import '@/styles/text.scss';
@import '@/styles/fields.scss';
@import '@/styles/buttons.scss';
@import '@/../node_modules/@progress/kendo-theme-material/dist/all.scss';
</style>
