import { provide, inject, reactive, App, readonly } from 'vue';
import {
  ITranslateState,
  ITranslateStore,
  TSetResponses,
  TSetLanguages,
  TLoadLanguages,
  TSetSelectedLanguage,
  TGetAcceptedExtensions,
  TGetAcceptedMimes,
  TSetTranslationType,
  TSetTranslateText,
  TTranslateTextAction,
  TSetFiles,
  TUploadFiles,
  TLanguageCodeDisplay,
  TReset,
  TRemoveResponse,
  ITranslationResponse,
  TLoadDocumentExtensions,
  TSetDocumentExtensions,
  ISupportedFileExtensions,
  ITextTranslationResponse,
  TranslationType,
  IDocumentStartDocumentTranslationResponse,
  IDocumentTranslationStatusRequestModel,
  TranslationStatusType,
  TSetIsTranslatingText,
  TTransactionReviewed,
  ILanguagesResponseModel,
  TSetDefaultLanguageCode,
  ITextTranslateRequest,
  IDocumentTextExtractionStatusRequestModel,
  TLoadTextExtractCapabilities,
  TSetTextExtractCapabilities,
  IDocumentStartTextExtractTranslationResponse,
  IDocumentTextExtractCapabilities,
  TSetActiveDocumentTranslationUploads,
  TSetIsPollingDocumentTranslation,
  TSetActiveDocumentTextExtractionUploads,
  TSetIsPollingDocumentTextExtraction,
  IDocumentTranslationRequestPropertiesModel,
  IDocumentTranslationStatusResponseModel,
  IDocumentTextExtractionRequestPropertiesModel,
  IDocumentTextExtractionStatusResponse
} from '@/store/contracts/translate';
import { IUploadInformation, IUploadStore, TSetStatusDisplay, TSetUploadState, TUploadCompleteCallback, UploadState } from '@/store/contracts/upload';
import { impl } from '@/utils/impl';
import mime from 'mime-types';
import {
  handleErrorStatusResult,
  IStatusHandler
} from '@/store/utils/apiResponseHandling';
import { sendRequest } from '@/store/utils/axiosUtils';
import { cleanFileName, isNullOrWhitespace } from '@/utils/stringUtils';
import { setLoginLogoutListener } from '@/store/contracts/loginStore';
import { TransactionLogRequestType, TransactionLogHelpfulnessRatingType } from '@/store/contracts/transactions';
import { delay } from '@/utils/systemUtils';

const TranslateStoreKey = Symbol('TranslateStore');

const createState = () => reactive(impl<ITranslateState>({
  responses: {},
  supportedLanguages: [],
  supportedDocumentExtensions: [],
  documentTextExtractCapabilities: { maxBytes: 0, maxFileSizeDisplay: '', fileExtensions: [] },
  selectedLanguageCode: '',
  translationType: TranslationType.Document,
  translateText: '',
  pages: [],
  files: undefined,
  isTranslatingText: false,
  defaultLanguageCode: '',
  activeDocumentTranslationUploads: [],
  isPollingDocumentTranslation: false,
  activeDocumentTextExtractionUploads: [],
  isPollingDocumentTextExtraction: false
}));

function setResponses (state: ITranslateState): TSetResponses {
  return (responses) => {
    state.responses = responses;
  };
}

function setLanguages (state: ITranslateState): TSetLanguages {
  return (languages) => {
    state.supportedLanguages = languages;
  };
}

function setDefaultLanguageCode (state: ITranslateState): TSetDefaultLanguageCode {
  return (defaultLanguageCode: string) => {
    state.defaultLanguageCode = defaultLanguageCode;
  };
}

function setDocumentExtensions (state: ITranslateState): TSetDocumentExtensions {
  return (extensions) => {
    state.supportedDocumentExtensions = extensions;
  };
}

function setTextExtractCapabilities (state: ITranslateState): TSetTextExtractCapabilities {
  return (textExtractCapabilities) => {
    state.documentTextExtractCapabilities = textExtractCapabilities;
  };
}

function setSelectedLanguage (state: ITranslateState): TSetSelectedLanguage {
  return (selectedLanguageCode) => {
    state.selectedLanguageCode = selectedLanguageCode;
  };
}

function setTranslationType (state: ITranslateState): TSetTranslationType {
  return (selectedTranslationType) => {
    state.translationType = selectedTranslationType;
  };
}

function setTranslateText (state: ITranslateState): TSetTranslateText {
  return (translateText) => {
    state.translateText = translateText;
  };
}

function setFiles (state: ITranslateState): TSetFiles {
  return (files) => {
    state.files = files;
  };
}

function setIsTranslatingText (state: ITranslateState): TSetIsTranslatingText {
  return (isTranslatingText) => {
    state.isTranslatingText = isTranslatingText;
  };
}

function setActiveDocumentTranslationUploads (state: ITranslateState): TSetActiveDocumentTranslationUploads {
  return (activeDocumentTranslationUploads) => {
    state.activeDocumentTranslationUploads = activeDocumentTranslationUploads;
  };
}

function setIsPollingDocumentTranslation (state: ITranslateState): TSetIsPollingDocumentTranslation {
  return (isPollingDocumentTranslation) => {
    state.isPollingDocumentTranslation = isPollingDocumentTranslation;
  };
}

function setActiveDocumentTextExtractionUploads (state: ITranslateState): TSetActiveDocumentTextExtractionUploads {
  return (activeDocumentTextExtractionUploads) => {
    state.activeDocumentTextExtractionUploads = activeDocumentTextExtractionUploads;
  };
}

function setIsPollingDocumentTextExtraction (state: ITranslateState): TSetIsPollingDocumentTextExtraction {
  return (isPollingDocumentTextExtraction) => {
    state.isPollingDocumentTextExtraction = isPollingDocumentTextExtraction;
  };
}

function transactionReviewed (state: ITranslateState): TTransactionReviewed {
  return (helpfulnessRating: TransactionLogHelpfulnessRatingType, responseName: string) => {
    const transactionContext = state.responses[responseName].transactionContext;
    if (transactionContext) {
      transactionContext.helpfulnessRating = helpfulnessRating;
    }
  };
}

function uploadFiles (state: ITranslateState): TUploadFiles {
  return (uploadStore: IUploadStore) => {
    if (!state.files || state.files.length === 0) {
      return;
    }
    setDefaultLanguageCode(state)(state.selectedLanguageCode);
    const filesArray = Array.from(state.files);
    filesArray.forEach(f => {
      const fileName = cleanFileName(f.name); // Just clean the string to prevent issues with the path.
      const fileTypeLower = f.type.toLowerCase();
      const isImage = fileTypeLower.includes('image') && !fileTypeLower.includes('tiff') && !fileTypeLower.includes('tif');
      switch (state.translationType) {
        case TranslationType.Document:
          uploadStore.upload('Translate', `/api/v1/precog/translate/document/start/${state.selectedLanguageCode}/${fileName}`, [f], onUploadDocumentTranslationSuccess(state));
          break;
        case TranslationType.TextExtraction:
          uploadStore.upload('Translate', `/api/v1/precog/extract/document/start/${isImage}/${fileName}`, [f], onUploadTextExtractFileSuccess(state));
          break;
        default:
          throw new Error(`Upload type ${f.type} on uploadFiles not found.`);
      }
    });
  };
}

function getFileKey (fileName: string, fileKeys: string[], iter?: number): string {
  const fileKey = iter === undefined ? fileName : `${fileName} (${iter})`;

  if (!fileKeys.includes(fileKey)) {
    return fileKey;
  }

  return getFileKey(fileName, fileKeys, iter === undefined ? 1 : iter + 1);
}

function statusDisplay (status: TranslationStatusType): string | undefined {
  switch (status) {
    case TranslationStatusType.Success:
    case TranslationStatusType.Failed:
      return undefined;
    case TranslationStatusType.Queued:
      return 'Queued';
    case TranslationStatusType.Running:
      return 'Running';
  }
}

function isStatusDone (status: TranslationStatusType): boolean {
  return status !== TranslationStatusType.Running && status !== TranslationStatusType.Queued;
}

function documentTranslationUploadComplete (state: ITranslateState, upload: IUploadInformation): void {
  setActiveDocumentTranslationUploads(state)(state.activeDocumentTranslationUploads.filter(info => info.id !== upload.id));
}

async function pollActiveDocumentTranslationUploads (state: ITranslateState, setStatusDisplay: TSetStatusDisplay, setUploadState: TSetUploadState): Promise<void> {
  if (state.isPollingDocumentTranslation) {
    return;
  }

  setIsPollingDocumentTranslation(state)(true);

  while (state.activeDocumentTranslationUploads.length > 0) {
    await delay(5000);

    const activeUploads = [...state.activeDocumentTranslationUploads];
    const requests: IDocumentTranslationRequestPropertiesModel[] = [];
    const startResponses: IDocumentStartDocumentTranslationResponse[] = [];
    for (const upload of activeUploads) {
      const startResponse: IDocumentStartDocumentTranslationResponse = upload.xhr?.data;
      startResponses.push(startResponse);
      requests.push({
        id: startResponse.id ?? '',
        resultsUri: startResponse.resultsUri ?? '',
        transactionLogId: startResponse.transactionContext.transactionLogId
      });
    }

    const languageDisplay = languageCodeDisplay(state)(state.selectedLanguageCode);
    const request: IDocumentTranslationStatusRequestModel = {
      requests: requests
    };

    let responseModel: IDocumentTranslationStatusResponseModel;

    const handler = impl<Partial<IStatusHandler<IDocumentTranslationStatusResponseModel>>>({
      onSuccess: async response => {
        responseModel = response;
        for (let i = 0; i < responseModel.statuses.length; i++) {
          const status = responseModel.statuses[i];
          const uploadInfo = activeUploads[i];
          const startResponse = startResponses[i];
          if (uploadInfo.cancelled) {
            documentTranslationUploadComplete(state, uploadInfo);
            continue;
          }
          setStatusDisplay(uploadInfo, statusDisplay(status.statusType));
          const done = isStatusDone(status.statusType);
          if (done) {
            const fileKey = getFileKey(`${uploadInfo.fileName} - ${languageDisplay}`, Object.keys(state.responses));
            const translationResponse: ITranslationResponse = {
              fileName: uploadInfo.fileName,
              sourceText: uploadInfo.fileName,
              destinationLanguage: languageDisplay ?? '',
              success: status.statusType === TranslationStatusType.Success,
              type: TranslationType.Document,
              transactionContext: startResponse.transactionContext,
              translatedText: undefined,
              translatedDocumentSasUrl: status.resultsSasUri,
              errorMessage: status.errorMessage
            };
            setResponses(state)({ ...state.responses, [fileKey]: translationResponse });
            setUploadState(uploadInfo, translationResponse.success ? UploadState.success : UploadState.failed);
            documentTranslationUploadComplete(state, uploadInfo);
          }
        }
      },
      onError: async () => {
        for (let i = 0; i < activeUploads.length; i++) {
          const uploadInfo = activeUploads[i];
          const startResponse = startResponses[i];
          if (uploadInfo.cancelled) {
            documentTranslationUploadComplete(state, uploadInfo);
            continue;
          }
          const failedFileKey = getFileKey(`${uploadInfo.fileName} - ${languageDisplay}`, Object.keys(state.responses));
          const failedResponse: ITranslationResponse = {
            fileName: uploadInfo.fileName,
            destinationLanguage: languageDisplay ?? '',
            sourceText: uploadInfo.fileName,
            success: false,
            type: TranslationType.Document,
            transactionContext: startResponse.transactionContext,
            translatedText: undefined,
            translatedDocumentSasUrl: undefined,
            errorMessage: startResponse.errorMessage ?? 'Error during translation.'
          };
          setResponses(state)({ ...state.responses, [failedFileKey]: failedResponse });
          setUploadState(uploadInfo, UploadState.failed);
          documentTranslationUploadComplete(state, uploadInfo);
        }
      },
      onCancel: async () => {
        // nothing to do here
      }
    });

    await handleErrorStatusResult(await sendRequest<IDocumentTranslationStatusResponseModel>(
      'api/v1/precog/translate/document/status',
      'post',
      request
    ), handler);
  }

  setIsPollingDocumentTranslation(state)(false);
}

function onUploadDocumentTranslationSuccess (state: ITranslateState): TUploadCompleteCallback {
  return (info: IUploadInformation[], setStatusDisplay: TSetStatusDisplay, setUploadState: TSetUploadState) => {
    const successUploadInfos: IUploadInformation[] = [];
    const languageDisplay = languageCodeDisplay(state)(state.selectedLanguageCode);
    for (const uploadInfo of info) {
      if (uploadInfo.cancelled) {
        continue;
      }
      const startResponse: IDocumentStartDocumentTranslationResponse = uploadInfo.xhr?.data;
      if (!startResponse.success) {
        const failedFileKey = getFileKey(`${uploadInfo.fileName} - ${languageDisplay}`, Object.keys(state.responses));
        const failedResponse: ITranslationResponse = {
          fileName: uploadInfo.fileName,
          destinationLanguage: languageDisplay ?? '',
          sourceText: uploadInfo.fileName,
          success: false,
          type: TranslationType.Document,
          transactionContext: startResponse.transactionContext,
          translatedText: undefined,
          translatedDocumentSasUrl: undefined,
          errorMessage: startResponse.errorMessage ?? 'Error during translation.'
        };
        setResponses(state)({ ...state.responses, [failedFileKey]: failedResponse });
        setUploadState(uploadInfo, UploadState.failed);
        continue;
      }
      successUploadInfos.push(uploadInfo);
    }

    if (successUploadInfos.length === 0) {
      return Promise.resolve();
    }
    setActiveDocumentTranslationUploads(state)([...state.activeDocumentTranslationUploads, ...successUploadInfos]);
    pollActiveDocumentTranslationUploads(state, setStatusDisplay, setUploadState);
    return Promise.resolve();
  };
}

function documentTextExtractionUploadComplete (state: ITranslateState, upload: IUploadInformation): void {
  setActiveDocumentTextExtractionUploads(state)(state.activeDocumentTextExtractionUploads.filter(info => info.id !== upload.id));
}

async function pollActiveDocumentTextExtractionUploads (state: ITranslateState, setStatusDisplay: TSetStatusDisplay, setUploadState: TSetUploadState): Promise<void> {
  if (state.isPollingDocumentTextExtraction) {
    return;
  }

  setIsPollingDocumentTextExtraction(state)(true);

  while (state.activeDocumentTextExtractionUploads.length > 0) {
    await delay(5000);

    const activeUploads = [...state.activeDocumentTextExtractionUploads];
    const requests: IDocumentTextExtractionRequestPropertiesModel[] = [];
    const startResponses: IDocumentStartTextExtractTranslationResponse[] = [];
    for (const upload of activeUploads) {
      const startResponse: IDocumentStartTextExtractTranslationResponse = upload.xhr?.data;
      startResponses.push(startResponse);
      requests.push({
        id: startResponse.id ?? '',
        transactionLogId: startResponse.transactionContext.transactionLogId,
        fileName: startResponse.fileName,
        translateToLanguageCode: state.selectedLanguageCode
      });
    }

    const languageDisplay = languageCodeDisplay(state)(state.selectedLanguageCode);
    const request: IDocumentTextExtractionStatusRequestModel = {
      requests: requests
    };

    let responseModel: IDocumentTextExtractionStatusResponse;

    const handler = impl<Partial<IStatusHandler<IDocumentTextExtractionStatusResponse>>>({
      onSuccess: async response => {
        responseModel = response;
        for (let i = 0; i < responseModel.statuses.length; i++) {
          const status = responseModel.statuses[i];
          const uploadInfo = activeUploads[i];
          const startResponse = startResponses[i];
          if (uploadInfo.cancelled) {
            documentTextExtractionUploadComplete(state, uploadInfo);
            continue;
          }
          setStatusDisplay(uploadInfo, statusDisplay(status.statusType));
          const done = isStatusDone(status.statusType);
          if (done) {
            const fileKey = getFileKey(`${uploadInfo.fileName} - ${languageDisplay}`, Object.keys(state.responses));
            const translationResponse: ITranslationResponse = {
              fileName: uploadInfo.fileName,
              destinationLanguage: languageDisplay ?? '',
              sourceText: uploadInfo.fileName,
              success: status.statusType === TranslationStatusType.Success,
              type: TranslationType.TextExtraction,
              transactionContext: startResponse.transactionContext,
              translatedText: undefined,
              pages: status.result?.pages,
              translatedDocumentSasUrl: startResponse.uploadedFileUri,
              sourceLanguage: status.result?.friendlyLanguage,
              isImage: startResponse.isImage,
              errorMessage: status.errorMessage
            };
            setResponses(state)({ ...state.responses, [fileKey]: translationResponse });
            setUploadState(uploadInfo, translationResponse.success ? UploadState.success : UploadState.failed);
            documentTextExtractionUploadComplete(state, uploadInfo);
          }
        }
      },
      onError: async () => {
        for (let i = 0; i < activeUploads.length; i++) {
          const uploadInfo = activeUploads[i];
          const startResponse = startResponses[i];
          if (uploadInfo.cancelled) {
            documentTextExtractionUploadComplete(state, uploadInfo);
            continue;
          }
          const failedFileKey = getFileKey(`${uploadInfo.fileName} - ${languageDisplay}`, Object.keys(state.responses));
          const failedResponse: ITranslationResponse = {
            fileName: uploadInfo.fileName,
            destinationLanguage: languageDisplay ?? '',
            sourceText: uploadInfo.fileName,
            success: false,
            type: TranslationType.TextExtraction,
            transactionContext: startResponse.transactionContext,
            translatedText: undefined,
            translatedDocumentSasUrl: undefined,
            isImage: undefined,
            errorMessage: startResponse.errorMessage ?? 'Error during translation.'
          };
          setResponses(state)({ ...state.responses, [failedFileKey]: failedResponse });
          setUploadState(uploadInfo, UploadState.failed);
          documentTextExtractionUploadComplete(state, uploadInfo);
        }
      },
      onCancel: async () => {
        // nothing to do here
      }
    });

    await handleErrorStatusResult(await sendRequest<IDocumentTextExtractionStatusResponse>(
      'api/v1/precog/extract/document/status',
      'post',
      request
    ), handler);
  }

  setIsPollingDocumentTextExtraction(state)(false);
}

function onUploadTextExtractFileSuccess (state: ITranslateState): TUploadCompleteCallback {
  return (info: IUploadInformation[], setStatusDisplay: TSetStatusDisplay, setUploadState: TSetUploadState) => {
    const successUploadInfos: IUploadInformation[] = [];
    const languageDisplay = languageCodeDisplay(state)(state.selectedLanguageCode);
    for (const uploadInfo of info) {
      if (uploadInfo.cancelled) {
        continue;
      }
      const startResponse: IDocumentStartTextExtractTranslationResponse = uploadInfo.xhr?.data;
      if (!startResponse.success) {
        const failedFileKey = getFileKey(`${uploadInfo.fileName} - ${languageDisplay}`, Object.keys(state.responses));
        const failedResponse: ITranslationResponse = {
          fileName: uploadInfo.fileName,
          destinationLanguage: languageDisplay ?? '',
          sourceText: uploadInfo.fileName,
          success: false,
          type: TranslationType.TextExtraction,
          transactionContext: startResponse.transactionContext,
          translatedText: undefined,
          translatedDocumentSasUrl: undefined,
          isImage: undefined,
          errorMessage: startResponse.errorMessage ?? 'Error during translation.'
        };
        setResponses(state)({ ...state.responses, [failedFileKey]: failedResponse });
        setUploadState(uploadInfo, UploadState.failed);
        continue;
      }
      successUploadInfos.push(uploadInfo);
    }

    if (successUploadInfos.length === 0) {
      return Promise.resolve();
    }
    setActiveDocumentTextExtractionUploads(state)([...state.activeDocumentTextExtractionUploads, ...successUploadInfos]);
    pollActiveDocumentTextExtractionUploads(state, setStatusDisplay, setUploadState);
    return Promise.resolve();
  };
}

function loadLanguages (state: ITranslateState): TLoadLanguages {
  return async () => {
    if (state.supportedLanguages.length > 0) {
      return;
    }
    const handler = impl<Partial<IStatusHandler<ILanguagesResponseModel>>>({
      onSuccess: async response => {
        setLanguages(state)(response.languages);
        setSelectedLanguage(state)(response.defaultLanguageCode);
        setDefaultLanguageCode(state)(response.defaultLanguageCode);
      }
    });
    await handleErrorStatusResult(await sendRequest<ILanguagesResponseModel>(
      '/api/v1/precog/languages',
      'get'
    ), handler);
  };
}

function loadDocumentExtensions (state: ITranslateState): TLoadDocumentExtensions {
  return async () => {
    if (state.supportedDocumentExtensions.length > 0) {
      return;
    }
    const handler = impl<Partial<IStatusHandler<ISupportedFileExtensions>>>({
      onSuccess: async response => {
        setDocumentExtensions(state)(response.fileExtensions);
      }
    });
    await handleErrorStatusResult(await sendRequest<ISupportedFileExtensions>(
      '/api/v1/precog/translate/fileExtensions',
      'get'
    ), handler);
  };
}

function loadTextExtractCapabilities (state: ITranslateState): TLoadTextExtractCapabilities {
  return async () => {
    if (state.documentTextExtractCapabilities.maxBytes > 0) {
      return;
    }
    const handler = impl<Partial<IStatusHandler<IDocumentTextExtractCapabilities>>>({
      onSuccess: async response => {
        setTextExtractCapabilities(state)({ maxBytes: response.maxBytes, maxFileSizeDisplay: response.maxFileSizeDisplay, fileExtensions: response.fileExtensions });
      }
    });
    await handleErrorStatusResult(await sendRequest<IDocumentTextExtractCapabilities>(
      '/api/v1/precog/extract/document/capabilities',
      'get'
    ), handler);
  };
}

function translateTextAction (state: ITranslateState): TTranslateTextAction {
  return async () => {
    setIsTranslatingText(state)(true);
    setDefaultLanguageCode(state)(state.selectedLanguageCode);
    const languageDisplay = languageCodeDisplay(state)(state.selectedLanguageCode);
    const key = `${state.translateText.length < 100 ? state.translateText : state.translateText.substring(0, 100)} - ${languageDisplay}`;
    const fileKey = getFileKey(key, Object.keys(state.responses));
    const request: ITextTranslateRequest = {
      toLanguageCode: state.selectedLanguageCode,
      content: state.translateText,
      requestType: TransactionLogRequestType.TextTranslation,
      transcriptionTransactionLogId: null,
      transcriptionFileName: null
    };

    const handler = impl<Partial<IStatusHandler<ITextTranslationResponse>>>({
      onSuccess: async textResponse => {
        const translationResponse: ITranslationResponse = {
          transactionContext: textResponse.transactionContext,
          success: textResponse.success,
          type: TranslationType.Text,
          sourceText: state.translateText,
          translatedText: textResponse.translatedText,
          translatedDocumentSasUrl: undefined,
          destinationLanguage: languageDisplay ?? '',
          sourceLanguage: textResponse.sourceLanguage,
          errorMessage: textResponse.errorMessage
        };
        setResponses(state)({ ...state.responses, [fileKey]: translationResponse });
        setIsTranslatingText(state)(false);
      }
    });
    await handleErrorStatusResult(await sendRequest<ITextTranslationResponse>(
      '/api/v1/precog/translate/text',
      'post',
      request
    ), handler);
  };
}

function getAcceptedDocumentMimes (state: ITranslateState): TGetAcceptedMimes {
  return () => {
    return state.supportedDocumentExtensions.map((e) => mime.lookup(e)).filter((m) => m !== false).map((m) => m as string);
  };
}

function getAcceptedDocumentExtensions (state: ITranslateState): TGetAcceptedExtensions {
  return () => {
    return state.supportedDocumentExtensions;
  };
}

function languageCodeDisplay (state: ITranslateState): TLanguageCodeDisplay {
  return (languageCode: string | null) => {
    if (languageCode === null) {
      return null;
    }

    return state.supportedLanguages
      .find((l) => l.languageCode === languageCode)?.name ?? null;
  };
}

function removeResponse (state: ITranslateState): TRemoveResponse {
  return (responseKey: string) => {
    delete state.responses[`${responseKey}`];
  };
}

function reset (state: ITranslateState): TReset {
  return () => {
    setResponses(state)({});
    setLanguages(state)([]);
    setDefaultLanguageCode(state)('');
    setDocumentExtensions(state)([]);
    setSelectedLanguage(state)('');
    setTranslationType(state)(TranslationType.Document);
    setTranslateText(state)('');
    setFiles(state)(undefined);
    setIsTranslatingText(state)(false);
  };
}

function createForState (state: ITranslateState): ITranslateStore {
  return {
    state: readonly(state),
    setResponses: setResponses(state),
    setLanguages: setLanguages(state),
    setDefaultLanguageCode: setDefaultLanguageCode(state),
    setDocumentExtensions: setDocumentExtensions(state),
    setTextExtractCapabilities: setTextExtractCapabilities(state),
    setSelectedLanguage: setSelectedLanguage(state),
    setTranslationType: setTranslationType(state),
    setTranslateText: setTranslateText(state),
    setFiles: setFiles(state),
    setIsTranslatingText: setIsTranslatingText(state),
    setActiveDocumentTranslationUploads: setActiveDocumentTranslationUploads(state),
    setIsPollingDocumentTranslation: setIsPollingDocumentTranslation(state),
    setActiveDocumentTextExtractionUploads: setActiveDocumentTextExtractionUploads(state),
    setIsPollingDocumentTextExtraction: setIsPollingDocumentTextExtraction(state),
    transactionReviewed: transactionReviewed(state),
    loadLanguages: loadLanguages(state),
    loadDocumentExtensions: loadDocumentExtensions(state),
    loadTextExtractCapabilities: loadTextExtractCapabilities(state),
    getAcceptedMimes: getAcceptedDocumentMimes(state),
    getAcceptedExtensions: getAcceptedDocumentExtensions(state),
    translateTextAction: translateTextAction(state),
    uploadFiles: uploadFiles(state),
    languageCodeDisplay: languageCodeDisplay(state),
    removeResponse: removeResponse(state),
    reset: reset(state),
    get noSelectedLanguage (): boolean {
      return !state || isNullOrWhitespace(state.selectedLanguageCode);
    },
    get noTranslateText (): boolean {
      return !state || isNullOrWhitespace(state.translateText);
    },
    get noFiles (): boolean {
      return !state || !state.files || state.files.length === 0;
    }
  };
}

export function provideStore (app?: App<Element>): void {
  const state = createState();
  const onLoginlogout = async () => createForState(state).reset();
  setLoginLogoutListener(
    TranslateStoreKey.toString(),
    onLoginlogout,
    onLoginlogout);
  if (app !== undefined) {
    app.provide(TranslateStoreKey, state);
  } else {
    provide(TranslateStoreKey, state);
  }
}

export function useStore (): ITranslateStore {
  const state = inject<ITranslateState>(TranslateStoreKey);
  if (state === undefined) {
    throw new Error('Using TranslateStore before providing it!');
  }
  return createForState(state);
}
