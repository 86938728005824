import { SortDescriptor } from '@progress/kendo-data-query';
import { GridSortDirection, IFilterChip, IGridColumnValuesResponseModel, IGridFilterModel, IGridDttmFilter, IGridQuickFilter, IGridQuickFilterValue, IGridSortModel } from '@/store/contracts/transactions';
import { impl } from '@/utils/impl';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { twoDigitDates } from '@/utils/dateUtils';
import { upperFirstLetter } from '@/utils/stringUtils';

export function mapSorts (sorts: SortDescriptor[]): IGridSortModel[] {
  return sorts.map(s => impl<IGridSortModel>({
    columnName: s.field.charAt(0).toUpperCase() + s.field.slice(1),
    sortDirection: s.dir === 'asc' ? GridSortDirection.Ascending : GridSortDirection.Descending
  }));
}

export function mapQuickFilters (gridQuickFilters: IGridQuickFilter[]): IGridFilterModel[] {
  return gridQuickFilters.filter(f => f.values.some(v => v.isSelected)).map(f => {
    return {
      columnName: upperFirstLetter(f.columnName)!,
      isExclude: f.isExclude,
      filterValues: f.values.filter(v => v.isSelected).map(v => v.value)
    };
  });
}

export function mapColumns (columns: GridColumnProps[]): string[] {
  return columns.filter(c => c.field && !c.hidden).sortBy(c => c.orderIndex).map(c => upperFirstLetter(c.field)!);
}

export function dateFilterDisplay (dttm: Date | null | undefined): string | null {
  return !dttm ? null : `${twoDigitDates(dttm.getMonth() + 1)}/${twoDigitDates(dttm.getDate())}/${dttm.getFullYear()}`;
}

export function filterDisplayValue (columnName: string, isExclude: boolean, value: string): string {
  let display = value;
  if (columnName === 'helpfulnessRating') {
    display = `Helpful: ${value}`;
  } else if (columnName === 'requestingUserOffice' && value === 'N/A') {
    display = `Office: ${value}`;
  } else if (columnName === 'requestingUserDivision' && value === 'N/A') {
    display = `Division: ${value}`;
  }
  return isExclude ? `EXCLUDE ${display}` : display;
}

function filterChipSortOrder (columnName: string): number {
  switch (columnName) {
    case 'requestType':
      return 0;
    case 'requestingUserFullName':
      return 1;
    case 'requestingUserDivision':
      return 2;
    case 'requestingUserOffice':
      return 3;
    case 'helpfulnessRating':
      return 4;
    default:
      return 5;
  }
}

export function dateFilterChipDisplayText (dttmFilter: IGridDttmFilter): string | null {
  if (!dttmFilter.minDttm && !dttmFilter.maxDttm) {
    return null;
  } else if (!dttmFilter.minDttm) {
    return `Before ${dateFilterDisplay(dttmFilter.maxDttm)}`;
  } else if (!dttmFilter.maxDttm) {
    return `After ${dateFilterDisplay(dttmFilter.minDttm)}`;
  } else {
    return `${dateFilterDisplay(dttmFilter.minDttm)}-${dateFilterDisplay(dttmFilter.maxDttm)}`;
  }
}

export function mapFilterChips (dttmFilter: IGridDttmFilter, gridQuickFilters: IGridQuickFilter[]): IFilterChip[] {
  if (!dttmFilter.minDttm && !dttmFilter.maxDttm && gridQuickFilters.length === 0) {
    return [];
  }

  const chips: IFilterChip[] = [];

  // Always display date filter first if we have one.
  const dateFilterChipText = dateFilterChipDisplayText(dttmFilter);
  if (dateFilterChipText) {
    chips.push({
      text: dttmFilter.isExclude ? `EXCLUDE ${dateFilterChipText}` : dateFilterChipText,
      columnName: 'requestDttm',
      value: 'dateFilter',
      isExclude: dttmFilter.isExclude
    });
  }

  // Display quick filter chips in column order then filter value.
  gridQuickFilters.sortBy(f => filterChipSortOrder(f.columnName)).forEach(f => {
    f.values.sortBy(v => v.value).filter(v => v.isSelected).forEach(v => {
      chips.push({
        text: filterDisplayValue(f.columnName, f.isExclude, v.value),
        columnName: f.columnName,
        value: v.value,
        isExclude: f.isExclude
      });
    });
  });

  return chips;
}

export function columnDisplayName (columnName: string, browserTimeZoneFriendly?: string): string {
  switch (columnName) {
    case 'referenceId':
      return 'Reference ID';
    case 'requestDttm':
      return !browserTimeZoneFriendly ? 'Date/Time' : `Date/Time (${browserTimeZoneFriendly})`;
    case 'source':
      return 'Source';
    case 'requestType':
      return 'Request Type';
    case 'sourceLanguage':
      return 'Source Language';
    case 'destinationLanguage':
      return 'Destination Language';
    case 'requestingUserFullName':
      return 'Submitted By';
    case 'requestingUserOffice':
      return 'Office';
    case 'requestingUserDivision':
      return 'Division';
    case 'resultDescription':
      return 'Transaction Result';
    case 'helpfulnessRating':
      return 'Was this helpful?';
    default:
      // Just return the column name back if no mapping
      return columnName;
  }
}

export function mapQuickFilterResponse (columnName: string, responseModel: IGridColumnValuesResponseModel | null): IGridQuickFilter {
  const values: IGridQuickFilterValue[] = responseModel === null ? [] : responseModel.values.map(v => {
    return {
      value: v,
      isSelected: false
    };
  });

  return {
    columnName: columnName,
    values: values,
    isExclude: false
  };
}
