import { DeepReadonly } from 'vue';

export enum ForgotPasswordDialogState {
  Inputting = 'Inputting',
  Loading = 'Loading',
  UserNotFound = 'UserNotFound',
  Error = 'Error',
  Success = 'Success'
}

export enum ForgotPasswordStatusTypeModel {
  Success,
  UserNotFound,
  UnknownError
}

export interface IForgotPasswordModel {
  email: string | null;
}

export interface IForgotPasswordResponseModel {
  status: ForgotPasswordStatusTypeModel;
}

export interface IForgotPasswordState {
  model: IForgotPasswordModel;
  dialogState: ForgotPasswordDialogState
}

export type TSetModel = (model: IForgotPasswordModel) => void;
export type TSetEmail = (email: string | null) => void;
export type TSetDialogState = (dialogState: ForgotPasswordDialogState) => void;
export type TSubmit = () => Promise<void>;

export interface IForgotPasswordStore {
  state: DeepReadonly<IForgotPasswordState>;
  setModel: TSetModel;
  setEmail: TSetEmail;
  setDialogState: TSetDialogState;
  submit: TSubmit
}
