import { impl } from '@/utils/impl';
import { App, inject, provide, reactive, readonly } from 'vue';
import { ITimeState, ITimeStore } from '@/store/contracts/time';

const TimeStoreKey = Symbol('TimeStore');

const createState = () => {
  const state = reactive(impl<ITimeState>({
    now: new Date()
  }));

  setInterval(() => {
    state.now = new Date();
  }, 1000);

  return state;
};

export function provideStore (app?: App<Element>): void {
  if (app !== undefined) {
    app.provide(TimeStoreKey, createState());
  } else {
    provide(TimeStoreKey, createState());
  }
}

export function useStore (): ITimeStore {
  const state = inject<ITimeState>(TimeStoreKey);
  if (state === undefined) {
    throw new Error('Using TimeStore before providing it!');
  }
  return {
    state: readonly(state)
  };
}
