import { IIndexable } from '@/utils/indexable';
import { reactive, readonly, DeepReadonly } from 'vue';

export interface ILeftDrawerState {
  open: boolean;
}

export type TToggleLeftDrawer = () => void;
export type TSetLeftDrawerState = (value: boolean) => void;

export interface ILeftDrawerStore {
  state: DeepReadonly<ILeftDrawerState>;
  toggleLeftDrawer: TToggleLeftDrawer;
  setLeftDrawerState: TSetLeftDrawerState;
}

export type TLeftDrawerToggleListener = (isOpen: boolean) => Promise<void>;

const leftDrawerToggleListeners: IIndexable<TLeftDrawerToggleListener> = reactive({});

export function getLeftDrawerToggleListeners (): DeepReadonly<IIndexable<TLeftDrawerToggleListener>> {
  return readonly(leftDrawerToggleListeners);
}

export function setLeftDrawerToggleListener (key: string, listener: TLeftDrawerToggleListener): void {
  leftDrawerToggleListeners[key] = listener;
}

export function removeLeftDrawerToggleListener (key: string): void {
  delete leftDrawerToggleListeners[key];
}
