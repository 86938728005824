import { DeepReadonly } from 'vue';

export enum UserRegisterDialogState {
  Inputting = 'Inputting',
  Loading = 'Loading',
  InvalidEmail = 'InvalidEmail',
  DuplicateEmail = 'DuplicateEmail',
  Error = 'Error',
  Success = 'Success'
}

export enum UserRegisterStatusTypeModel {
  Success,
  InvalidEmail,
  DuplicateEmail,
  UnknownError
}

export interface IUserRegisterModel {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface IUserRegisterResponseModel {
  status: UserRegisterStatusTypeModel;
}

export interface IUserRegisterState {
  model: IUserRegisterModel;
  dialogState: UserRegisterDialogState
}

export type TSetModel = (model: IUserRegisterModel) => void;
export type TSetEmail = (email: string | null) => void;
export type TSetFirstName = (firstName: string | null) => void;
export type TSetLastName = (lastName: string | null) => void;
export type TSetDialogState = (dialogState: UserRegisterDialogState) => void;
export type TSubmit = () => Promise<void>;

export interface IUserRegisterStore {
  state: DeepReadonly<IUserRegisterState>;
  setModel: TSetModel;
  setEmail: TSetEmail;
  setFirstName: TSetFirstName;
  setLastName: TSetLastName;
  setDialogState: TSetDialogState;
  submit: TSubmit
}
