import { DeepReadonly } from 'vue';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { CancelTokenSource } from 'axios';
import { IIndexable } from '@/utils/indexable';

export enum TransactionLogRequestType {
  Transcription,
  DocumentTranslation,
  TextTranslation,
  TranscriptionTranslation,
  TranscriptionPhraseTranslation,
}

export enum TransactionLogHelpfulnessRatingType {
  No,
  Maybe,
  Yes
}

export enum GridSortDirection {
  Ascending,
  Descending
}

export interface ITransactionContext {
  transactionLogId: number;
  referenceId: string;
  helpfulnessRating?: TransactionLogHelpfulnessRatingType;
}

export interface IFilterChip {
  text: string;
  columnName: string;
  value: string;
  isExclude: boolean;
}

export interface ITransactionHistoryReviewRequestModel {
  transactionLogId: number;
  helpfulnessRating: TransactionLogHelpfulnessRatingType;
}

export interface IGridSortModel {
  columnName: string;
  sortDirection: GridSortDirection;
}

export interface IGridFilterModel {
  columnName: string;
  isExclude: boolean;
  filterValues: (string | null)[];
}

export interface ITransactionHistoryRecord {
  referenceId: string;
  requestDttm: Date;
  source: string;
  requestType: string;
  sourceLanguage: string;
  destinationLanguage: string;
  requestingUserFullName: string;
  requestingUserOffice: string;
  requestingUserDivision: string;
  resultDescription: string;
  helpfulnessRating: string;
}

export interface ITransactionHistoryGridRequestModel {
  offset: number;
  limit: number | null;
  minRequestDttm: Date | null;
  maxRequestDttm: Date | null;
  isDttmFilterExclude: boolean;
  sorts: IGridSortModel[];
  filters: IGridFilterModel[];
}

export interface ITransactionHistoryGridResponseModel {
  totalRecords: number;
  yesReviewCount: number;
  maybeReviewCount: number;
  noReviewCount: number;
  records: ITransactionHistoryRecord[];
}

export interface IUsageTotalsModel {
  totalTransactions: number;
  divisionCount: number;
  officeCount: number;
  userCount: number;
}

export interface IHelpfulnessRatingUsageModel {
  yesReviewCount: number;
  maybeReviewCount: number;
  noReviewCount: number;
  unreviewedCount: number;
}

export interface IGenericUsageListingModel {
  name: string;
  frequency: number;
}

export interface IGenericUsageModel {
  usageCount: number;
  averageUsage: number;
  topListings: IGenericUsageListingModel[];
}

export interface IRequestTypeUsageModel {
  totalCount: number;
  successCount: number;
  erroredCount: number;
}

export interface IRequestTypesUsageModel {
  transcriptionUsage: IRequestTypeUsageModel;
  totalTranslationUsage: IRequestTypeUsageModel;
  documentTranslationUsage: IRequestTypeUsageModel;
  documentTextExtractionUsage: IRequestTypeUsageModel;
  textTranslationUsage: IRequestTypeUsageModel;
  transcriptionTranslationUsage: IRequestTypeUsageModel;
}

export interface ITransactionHistoryUsageRequestModel {
  minRequestDttm: Date | null;
  maxRequestDttm: Date | null;
  isDttmFilterExclude: boolean;
  filters: IGridFilterModel[];
}

export interface ITransactionHistoryUsageResponseModel {
  minRecordDttm: Date;
  maxRecordDttm: Date;
  usageTotals: IUsageTotalsModel;
  helpfulnessRatingUsage: IHelpfulnessRatingUsageModel;
  sourceLanguageUsage: IGenericUsageModel;
  requestTypesUsage: IRequestTypesUsageModel;
  divisionUsage: IGenericUsageModel;
  officeUsage: IGenericUsageModel;
  userUsage: IGenericUsageModel;
}

export interface IGridColumnValuesRequestModel {
  columnName: string;
}

export interface IGridColumnValuesResponseModel {
  values: string[];
}

export interface ITransactionHistoryExportRequestModel {
  minRequestDttm: Date | null;
  maxRequestDttm: Date | null;
  isDttmFilterExclude: boolean;
  sorts: IGridSortModel[];
  filters: IGridFilterModel[];
  columnNames: string[];
  clientTimeZoneFriendly: string;
  clientTimeZoneOffset: number;
}

export interface ITransactionHistoryExportResponseModel {
  success: boolean;
  exportLocation: string | null;
  errorMessage: string | null;
}

export interface IGridData {
  gridItems: ITransactionHistoryRecord[];
  loadedOffset: number;
  maxLoadedIndex: number;
}

export interface IGridQuickFilterValue {
  value: string;
  isSelected: boolean;
}

export interface IGridDttmFilter {
  minDttm: Date | null;
  maxDttm: Date | null;
  isExclude: boolean;
}

export interface IGridQuickFilter {
  columnName: string;
  values: IGridQuickFilterValue[];
  isExclude: boolean;
}

export interface ITransactionsState {
  dttmFilter: IGridDttmFilter;
  gridLoadCancelToken: CancelTokenSource | null;
  gridData: IGridData;
  totalRecords: number;
  yesReviewCount: number;
  maybeReviewCount: number;
  noReviewCount: number;
  browserTimeZoneFriendly: string;
  browserTimeZoneOffset: number;
  gridSorts: SortDescriptor[];
  gridColumns: GridColumnProps[];
  gridQuickFilters: IIndexable<IGridQuickFilter>;
  usageLoadCancelToken: CancelTokenSource | null;
  usage: ITransactionHistoryUsageResponseModel | null;
}

export type TSetDttmFilter = (minDttm: Date | null, maxDttm: Date | null, isExclude: boolean) => void;
export type TSetGridLoadCancelToken = (gridLoadCancelToken: CancelTokenSource | null) => void;
export type TSetGridData = (gridItems: ITransactionHistoryRecord[], loadedOffset: number, maxLoadedIndex: number) => void;
export type TSetTotalRecords = (totalRecords: number) => void;
export type TSetYesReviewCount = (yesReviewCount: number) => void;
export type TSetMaybeReviewCount = (maybeReviewCount: number) => void;
export type TSetNoReviewCount = (noReviewCount: number) => void;
export type TSetBrowserTimeZoneFriendly = (browserTimeZoneFriendly: string) => void;
export type TSetBrowserTimeZoneOffset = (browserTimeZoneOffset: number) => void;
export type TSetGridSorts = (sorts: SortDescriptor[]) => void;
export type TSetGridColumns = (columns: GridColumnProps[]) => void;
export type TSetGridQuickFilters = (gridQuickFilters: IIndexable<IGridQuickFilter>) => void;
export type TSetUsageLoadCancelToken = (usageLoadCancelToken: CancelTokenSource | null) => void;
export type TSetUsage = (usage: ITransactionHistoryUsageResponseModel | null) => void;
export type TLoadRecords = (offset: number, limit: number, fullRefresh: boolean) => Promise<void>;
export type TGetGridRecords = (offset: number, limit: number, loadedOffset: number, gridItems: readonly ITransactionHistoryRecord[]) => ITransactionHistoryRecord[];
export type TExportRecords = () => Promise<ITransactionHistoryExportResponseModel | null>;
export type TReviewTransaction = (transactionLogId: number, helpfulnessRating: TransactionLogHelpfulnessRatingType) => Promise<void>;
export type TLoadQuickFilter = (columnName: string) => Promise<void>;
export type TGetQuickFilter = (columnName: string) => IGridQuickFilter;
export type TApplyQuickFilter = (columnName: string, quickFilter: IGridQuickFilter) => void;
export type TRemoveFilter = (columnName: string, value: string | null) => void;
export type TLoadUsage = () => Promise<void>;
export type TCancelGridLoad = () => void;
export type TCancelUsageLoad = () => void;
export type TReset = () => void;

export interface ITransactionsStore {
  state: DeepReadonly<ITransactionsState>;
  setDttmFilter: TSetDttmFilter;
  setGridLoadCancelToken: TSetGridLoadCancelToken;
  setGridData: TSetGridData;
  setTotalRecords: TSetTotalRecords;
  setYesReviewCount: TSetYesReviewCount;
  setMaybeReviewCount: TSetMaybeReviewCount;
  setNoReviewCount: TSetNoReviewCount;
  setBrowserTimeZoneFriendly: TSetBrowserTimeZoneFriendly;
  setBrowserTimeZoneOffset: TSetBrowserTimeZoneOffset;
  setGridSorts: TSetGridSorts;
  setGridColumns: TSetGridColumns;
  setGridQuickFilters: TSetGridQuickFilters;
  setUsageLoadCancelToken: TSetUsageLoadCancelToken;
  setUsage: TSetUsage;
  loadRecords: TLoadRecords;
  getGridRecords: TGetGridRecords;
  exportRecords: TExportRecords;
  reviewTransaction: TReviewTransaction;
  loadQuickFilter: TLoadQuickFilter;
  getQuickFilter: TGetQuickFilter;
  applyQuickFilter: TApplyQuickFilter;
  removeFilter: TRemoveFilter;
  loadUsage: TLoadUsage;
  cancelGridLoad: TCancelGridLoad;
  cancelUsageLoad: TCancelUsageLoad;
  reset: TReset;
  readonly filterChips: IFilterChip[];
  readonly isLoadingGrid: boolean;
  readonly isLoadingUsage: boolean;
}
