import { impl } from '@/utils/impl';
import { App, inject, provide, reactive, readonly } from 'vue';
import {
  IForgotPasswordResponseModel,
  IForgotPasswordStore,
  IForgotPasswordState,
  TSubmit,
  TSetEmail,
  TSetModel,
  TSetDialogState,
  ForgotPasswordDialogState,
  ForgotPasswordStatusTypeModel
} from '@/store/contracts/users/forgotPassword';
import validationModel, { ForgotPasswordModelFieldName } from '@/validation/forgotPasswordValidationModel';
import { store as validationStore } from '@/store/validation';
import { sendRequest } from '@/store/utils/axiosUtils';
import {
  handleErrorStatus,
  IStatusHandler
} from '@/store/utils/apiResponseHandling';
import { IIndexable } from '@/utils/indexable';

const ForgotPasswordStoreKey = Symbol('ForgotPasswordStore');

const createState = () => {
  validationStore.clear(validationModel);
  return reactive(impl<IForgotPasswordState>({
    model: {
      email: null
    },
    dialogState: ForgotPasswordDialogState.Inputting
  }));
};

function setEmail (state: IForgotPasswordState): TSetEmail {
  return (email) => {
    state.model.email = email;
  };
}

function setModel (state: IForgotPasswordState): TSetModel {
  return (model) => {
    state.model = model;
  };
}

function setDialogState (state: IForgotPasswordState): TSetDialogState {
  return (dialogState) => {
    state.dialogState = dialogState;
  };
}

const forgotPasswordErrorPropToValidationProp: IIndexable<string> = {
  Email: ForgotPasswordModelFieldName.Email
};

function submit (state: IForgotPasswordState): TSubmit {
  return async () => {
    setDialogState(state)(ForgotPasswordDialogState.Loading);

    const valid = await validationStore.doValidation({
      model: validationModel,
      value: state.model
    });

    if (!valid) {
      setDialogState(state)(ForgotPasswordDialogState.Inputting);
      return;
    }

    const handler = impl<Partial<IStatusHandler<IForgotPasswordResponseModel>>>({
      onError: async (r) => {
        const status = r?.status ?? 500;
        if (status !== 500) {
          const data = r?.data ?? {};
          let unhandled = false;
          Object.keys(data).forEach((errKey) => {
            const vProp = forgotPasswordErrorPropToValidationProp[errKey];
            if (vProp) {
              validationStore.setModelFieldResult(validationModel.modelName, vProp, data[errKey].join('\n'));
            } else {
              unhandled = true;
            }
          });
          if (!unhandled) {
            return;
          }
        }
        setDialogState(state)(ForgotPasswordDialogState.Error);
      },
      onSuccess: async (r) => {
        switch (r.status) {
          case ForgotPasswordStatusTypeModel.Success: {
            setDialogState(state)(ForgotPasswordDialogState.Success);
            break;
          }
          case ForgotPasswordStatusTypeModel.UserNotFound: {
            setDialogState(state)(ForgotPasswordDialogState.UserNotFound);
            break;
          }
          case ForgotPasswordStatusTypeModel.UnknownError: {
            setDialogState(state)(ForgotPasswordDialogState.Error);
            break;
          }
        }
      }
    });
    await handleErrorStatus(await sendRequest<IForgotPasswordResponseModel>(
      '/api/v1/users/forgotPassword',
      'post',
      state.model
    ), handler);
  };
}

const createForState = (state: IForgotPasswordState) => impl<IForgotPasswordStore>({
  state: readonly(state),
  setModel: setModel(state),
  setEmail: setEmail(state),
  setDialogState: setDialogState(state),
  submit: submit(state)
});

export function provideStore (app?: App<Element>): void {
  const state = createState();
  if (app !== undefined) {
    app.provide(ForgotPasswordStoreKey, state);
  } else {
    provide(ForgotPasswordStoreKey, state);
  }
}

export function useStore (): IForgotPasswordStore {
  const state = inject<IForgotPasswordState>(ForgotPasswordStoreKey);
  if (state === undefined) {
    throw new Error('Using ForgotPasswordStore before providing it!');
  }
  return createForState(state);
}
