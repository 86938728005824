import { RouteLocationNormalized, Router } from 'vue-router';
import { store as loginStore } from '@/store/login';
import { LoginRequirement, UserRole } from '@/store/contracts/loginStore';
import { LoginRoutes } from '@/router/routes/login';
import { HomeRoutes } from './routes/home';
import { UsersRoutes } from '@/router/routes/users';
import { TransactionRoutes } from './routes/transactions';

export function registerGuards (router: Router): void {
  router.beforeEach((to, from, next) => {
    const toAuthorizedRoles: UserRole[] | undefined = to.meta.authorizedRoles as any;
    if (!toAuthorizedRoles || toAuthorizedRoles.some(loginStore.hasRole)) {
      return hasLoginRequirements(to) ? next() : next(HomeRoutes.transcribe);
    }
    return next({ name: LoginRoutes.login });
  });
}

function hasLoginRequirements (to: RouteLocationNormalized): boolean {
  if (to.name && to.name !== HomeRoutes.transcribe) {
    const validLoginRequirements = !loginStore.state.info || loginStore.state.info.loginRequirements === LoginRequirement.None;
    const isSessionRoute = contains(HomeRoutes, to.name) || contains(UsersRoutes, to.name || contains(TransactionRoutes, to.name));
    return validLoginRequirements || !isSessionRoute;
  }
  return true;
}

function contains (routeEnum, toName): boolean {
  return Object.values(routeEnum).includes(toName as typeof routeEnum);
}
