import { impl } from '@/utils/impl';
import { reactive, readonly, provide, inject, App } from 'vue';
import {
  handleErrorStatusResult,
  IStatusHandler,
  ResponseStatus
} from '@/store/utils/apiResponseHandling';
import { sendRequest } from '@/store/utils/axiosUtils';
import {
  DivisionOfficeGridItemType,
  IDivisionOfficeGridItem,
  IDivisionsGridState,
  IDivisionsGridStore,
  IAddDivisionModel,
  IDivisionModel,
  IAddOfficeModel,
  IOfficeModel,
  IApplyDivisionsRequestModel,
  TAddDivision,
  TAddOffice,
  TDeleteDivision,
  TDeleteOffice,
  TEditDivision,
  TEditOffice,
  TLoadGridItems,
  TSetGridItems
} from '@/store/contracts/users/divisionsGrid';
import { IDivisionListing } from '@/store/contracts/users/divisionsSelect';

const DivisionsGridStoreKey = Symbol('DivisionsGridStore');

const createState = () => reactive(impl<IDivisionsGridState>({
  gridItems: []
}));

function setGridItems (state: IDivisionsGridState): TSetGridItems {
  return (gridItems) => {
    state.gridItems = gridItems;
  };
}

function loadGridItems (state: IDivisionsGridState): TLoadGridItems {
  return async () => {
    const handler = impl<Partial<IStatusHandler<IDivisionListing[]>>>({
      onSuccess: async (divisionListings) => {
        const gridItems: IDivisionOfficeGridItem[] = [];
        divisionListings.forEach(d => {
          // Get our division grid item in there.
          gridItems.push(impl<IDivisionOfficeGridItem>({
            type: DivisionOfficeGridItemType.division,
            divisionId: d.division.id,
            divisionName: d.division.name,
            officeName: null,
            officeId: null
          }));

          // Get our office grid items in there.
          d.offices.forEach(o => gridItems.push(impl<IDivisionOfficeGridItem>({
            type: DivisionOfficeGridItemType.office,
            divisionId: d.division.id,
            divisionName: d.division.name,
            officeName: o.name,
            officeId: o.officeId
          })));
        });

        setGridItems(state)(gridItems);
      }
    });

    await handleErrorStatusResult(await sendRequest<IDivisionListing[]>(
      '/api/v1/users/divisions',
      'get'
    ), handler);
  };
}

function addDivision (state: IDivisionsGridState): TAddDivision {
  return async (divisionName) => {
    const addDivision: IAddDivisionModel = {
      divisionName: divisionName,
      officeNames: []
    };
    const addDivisions: IAddDivisionModel[] = [addDivision];
    applyDivisions(state, { addDivisions: addDivisions });
  };
}

function addOffice (state: IDivisionsGridState): TAddOffice {
  return async (officeName, divisionId) => {
    const addOffice: IAddOfficeModel = {
      name: officeName,
      divisionId: divisionId
    };
    const addOffices: IAddOfficeModel[] = [addOffice];
    applyDivisions(state, { addOffices: addOffices });
  };
}

function editDivision (state: IDivisionsGridState): TEditDivision {
  return async (divisionId, name) => {
    const editDivision: IDivisionModel = {
      id: divisionId,
      name: name
    };
    const editDivisions: IDivisionModel[] = [editDivision];
    applyDivisions(state, { editDivisions: editDivisions });
  };
}

function editOffice (state: IDivisionsGridState): TEditOffice {
  return async (officeId, name, divisionId) => {
    const editOffice: IOfficeModel = {
      divisionId: divisionId,
      name: name,
      officeId: officeId
    };
    const editOffices: IOfficeModel[] = [editOffice];
    applyDivisions(state, { editOffices: editOffices });
  };
}

function deleteDivision (state: IDivisionsGridState): TDeleteDivision {
  return async (divisionId) => {
    const removeDivisionIds: number[] = [divisionId];
    applyDivisions(state, { removeDivisionIds: removeDivisionIds });
  };
}

function deleteOffice (state: IDivisionsGridState): TDeleteOffice {
  return async (officeId) => {
    const removeOfficeIds: number[] = [officeId];
    applyDivisions(state, { removeOfficeIds: removeOfficeIds });
  };
}

async function applyDivisions (state: IDivisionsGridState, applyDivision: { editDivisions?: IDivisionModel[], editOffices?: IOfficeModel[], addDivisions?: IAddDivisionModel[], addOffices?: IAddOfficeModel[], removeDivisionIds?: number[], removeOfficeIds?: number[] }): Promise<void> {
  const request: IApplyDivisionsRequestModel = {
    editDivisions: applyDivision.editDivisions ?? [],
    editOffices: applyDivision.editOffices ?? [],
    addDivisions: applyDivision.addDivisions ?? [],
    addOffices: applyDivision.addOffices ?? [],
    removeDivisionIds: applyDivision.removeDivisionIds ?? [],
    removeOfficeIds: applyDivision.removeOfficeIds ?? []
  };
  const handler = impl<Partial<IStatusHandler<ResponseStatus.Success>>>({
    onSuccess: async () => {
      await loadGridItems(state)();
    }
  });

  await handleErrorStatusResult(await sendRequest<ResponseStatus.Success>(
    '/api/v1/users/divisions/apply',
    'post',
    request
  ), handler);
}

const createForState = (state: IDivisionsGridState) => impl<IDivisionsGridStore>({
  state: readonly(state),
  setGridItems: setGridItems(state),
  loadGridItems: loadGridItems(state),
  addDivision: addDivision(state),
  addOffice: addOffice(state),
  editDivision: editDivision(state),
  editOffice: editOffice(state),
  deleteDivision: deleteDivision(state),
  deleteOffice: deleteOffice(state),
  get divisions (): IDivisionOfficeGridItem[] {
    return state.gridItems.filter(i => i.type === DivisionOfficeGridItemType.division);
  },
  get offices (): IDivisionOfficeGridItem[] {
    return state.gridItems.filter(i => i.type === DivisionOfficeGridItemType.office);
  }
});

export function provideStore (app?: App<Element>): void {
  const state = createState();
  if (app !== undefined) {
    app.provide(DivisionsGridStoreKey, state);
  } else {
    provide(DivisionsGridStoreKey, state);
  }
}

export function useStore (): IDivisionsGridStore {
  const state = inject<IDivisionsGridState>(DivisionsGridStoreKey);
  if (state === undefined) {
    throw new Error('Using DivisionsGridStore before providing it!');
  }
  return createForState(state);
}
