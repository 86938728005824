import Axios, {
  AxiosInstance,
  AxiosAdapter,
  AxiosResponse,
  AxiosHeaders,
  InternalAxiosRequestConfig
} from 'axios';
import { store as loginStore } from '@/store/login';
import router from '@/router';
import { LoginRoutes } from '@/router/routes/login';
import { getLogoutListeners } from '@/store/contracts/loginStore';
import { delay } from '@/utils/systemUtils';

export interface IAxiosSessionRefreshOptions {
    axios?: AxiosInstance;
}

const handleResponse = (r: AxiosResponse) => {
  if (r.status === 200) {
    return true;
  } else {
    if (r.status === 401 || r.status === 403) {
      const listeners = getLogoutListeners();
      Object.values(listeners).forEach(l => l());
      loginStore.setUserInfo(null);
      router.push({ name: LoginRoutes.login });
    }
    return false;
  }
};

const isRefreshing = () => {
  const valueStr = localStorage.getItem('is-refreshing');
  return valueStr !== null;
};

const setIsRefreshing = () => {
  localStorage.setItem('is-refreshing', 'true');
};

const clearIsRefreshing = () => {
  localStorage.removeItem('is-refreshing');
};

const waitNotRefreshing = async () => {
  if (isRefreshing()) {
    console.log('Looks like another browser tab is refreshing the session.  Waiting for that.');
    const timeout = 2500;
    const waitTime = 10;
    let waited = 0;
    do {
      await delay(waitTime);
      waited += waitTime;
    } while (isRefreshing() && waited < timeout);
    if (isRefreshing()) {
      console.log('Still refreshing?  Someone must have closed a browser tab or something.');

      return false;
    }

    return true;
  }

  return false;
};

let refreshingPromise: Promise<boolean> | null = null;

async function refreshSession (adapter: AxiosAdapter): Promise<boolean> {
  if (refreshingPromise !== null) {
    return refreshingPromise;
  }
  const otherTabRefreshed = await waitNotRefreshing();
  if (otherTabRefreshed) {
    return true;
  }
  setIsRefreshing();
  console.log('Refreshing session...');
  refreshingPromise = Axios.request({
    url: '/api/v1/users/refresh',
    withCredentials: true,
    method: 'post',
    headers: new AxiosHeaders({
      Accept: 'application/json, text/plain, */*'
    }),
    adapter: adapter
  }).then(r => {
    return handleResponse(r);
  }).catch(e => {
    return handleResponse(e.response);
  });

  const result = await refreshingPromise;
  clearIsRefreshing();
  refreshingPromise = null;

  return result;
}

export function registerSessionRefreshAdapter (options?: IAxiosSessionRefreshOptions): void {
  const opts = options ?? {};
  const axios: AxiosInstance = opts.axios ?? Axios;

  if (!axios.defaults.adapter) {
    throw new Error(`
        No adapter on axios.  For axiosSessionRefreshAdapter to work,
        The axios instance needs a default adapter to submit requests to.
        By default, the axios.defaults.adapter should not be null.
        See: https://github.com/axios/axios/pull/437
    `);
  }

  const defaultAdapter: AxiosAdapter = axios.defaults.adapter as AxiosAdapter;

  axios.defaults.adapter = async (config: InternalAxiosRequestConfig) => {
    try {
      const interimConfig = config;
      interimConfig.adapter = defaultAdapter;
      return await Axios.request(interimConfig);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        const refreshed = await refreshSession(defaultAdapter);
        if (refreshed) {
          const interimConfig = config;
          interimConfig.adapter = defaultAdapter;
          return await Axios.request(interimConfig);
        }
      }
      throw e;
    }
  };
}

export async function doRefreshSession (axios?: AxiosInstance): Promise<void> {
  const instance: AxiosInstance = axios ?? Axios;

  if (!instance.defaults.adapter) {
    throw new Error(`
        No adapter on axios.  For axiosSessionRefreshAdapter to work,
        The axios instance needs a default adapter to submit requests to.
        By default, the axios.defaults.adapter should not be null.
        See: https://github.com/axios/axios/pull/437
    `);
  }

  const defaultAdapter: AxiosAdapter = instance.defaults.adapter as AxiosAdapter;
  await refreshSession(defaultAdapter);
}
