import Axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { ApiResponse, ResponseStatus } from './apiResponseHandling';

export async function sendRequest<T> (
  url: string,
  method: 'get' | 'post' | 'delete',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data?: any,
  cancelToken?: CancelToken
): Promise<ApiResponse<T>> {
  try {
    let config: AxiosRequestConfig;
    if (method === 'get') {
      config = {
        url,
        method,
        withCredentials: true,
        params: data,
        cancelToken: cancelToken
      };
    } else {
      config = {
        url,
        method,
        withCredentials: true,
        data,
        cancelToken
      };
    }

    const result = await Axios.request<T>(config);
    return [result.data, result];
  } catch (e) {
    if (e.response && e.response.status === 401) {
      return [ResponseStatus.NotAuthenticated, e.response];
    } else if (e.response && e.response.status === 404) {
      return [ResponseStatus.NotFound, e.response];
    } else if (Axios.isCancel(e)) {
      return [ResponseStatus.Cancel, null];
    }
    return [ResponseStatus.Error, e.response ?? null];
  }
}
