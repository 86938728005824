import {
  IUserInfo,
  ILoginState,
  ILoginStore,
  TSetUserInfo,
  TSignIn,
  IPasswordLoginModel,
  TSignOut,
  THasRole,
  UserRole,
  TIsLoggedIn,
  getLogoutListeners,
  getLoginListeners,
  TLoginRequirementsComplete,
  LoginRequirement
} from '@/store/contracts/loginStore';
import { impl } from '@/utils/impl';
import { nullable } from '@/utils/nullable';
import { App, inject, provide, reactive, readonly } from 'vue';
import { sendRequest } from '@/store/utils/axiosUtils';
import {
  chainHandlers,
  handleErrorStatus,
  handleErrorStatusResult,
  IStatusHandler
} from '@/store/utils/apiResponseHandling';
import { Settings } from '@/settings';

const LoginStoreKey = Symbol('LoginStore');

const getInfoFromLocalStorage = () => {
  const infoStr = localStorage.getItem('userInfo');
  return nullable(infoStr, (i) => JSON.parse(i) as IUserInfo) ?? null;
};

const setInfoInLocalStorage = (info: IUserInfo | null) => {
  if (info === null) {
    localStorage.removeItem('userInfo');
  } else {
    localStorage.setItem('userInfo', JSON.stringify(info));
  }
};

const createState = () => reactive(impl<ILoginState>({
  info: getInfoFromLocalStorage()
}));

function setUserInfo (state: ILoginState): TSetUserInfo {
  return (info: IUserInfo | null) => {
    state.info = info;
    setInfoInLocalStorage(info);
  };
}

function loginRequirementsComplete (state: ILoginState): TLoginRequirementsComplete {
  return () => {
    if (!state.info) {
      return;
    }

    setUserInfo(state)(impl<IUserInfo>({
      id: state.info.id,
      loginRequirements: LoginRequirement.None,
      email: state.info.email,
      firstName: state.info.firstName,
      lastName: state.info.lastName,
      roles: state.info.roles,
      officeId: state.info.officeId
    }));
  };
}

function hasRole (state: ILoginState): THasRole {
  return (role: UserRole) => {
    return state.info !== null && state.info.roles.includes(role);
  };
}

function isLoggedIn (state: ILoginState): TIsLoggedIn {
  return () => {
    return state.info !== null && state.info.roles.length > 0;
  };
}

function signIn (state: ILoginState): TSignIn {
  return async (model: IPasswordLoginModel, handler?: Partial<IStatusHandler<IUserInfo>>) => {
    const result = await handleErrorStatusResult(await sendRequest<IUserInfo>(
      '/api/v1/users/login',
      'post',
      model),
    chainHandlers({
      onSuccess: async (i) => {
        const listeners = getLoginListeners();
        Object.values(listeners).forEach(l => l());
        setUserInfo(state)(i);
      }
    }, handler));

    return !!result;
  };
}

const signOut: TSignOut = async (handler?: Partial<IStatusHandler<void>>) => {
  const isError = await handleErrorStatus(await sendRequest<void>(
    '/api/v1/users/logout',
    'post'
  ), chainHandlers({
    onSuccess: async () => {
      const listeners = getLogoutListeners();
      Object.values(listeners).forEach(l => l());
    }
  }, handler));
  return !isError;
};

const storeState = createState();

const createForState = (state: ILoginState) => impl<ILoginStore>({
  state: readonly(state),
  setUserInfo: setUserInfo(state),
  loginRequirementsComplete: loginRequirementsComplete(state),
  hasRole: hasRole(state),
  signIn: signIn(state),
  signOut,
  isLoggedIn: isLoggedIn(state),
  get userRoles (): UserRole[] {
    return Settings.allowVision ? [UserRole.User, UserRole.Admin, UserRole.Vision] : [UserRole.User, UserRole.Admin];
  }
});

/**
 * Store implementation that can be accessed outside of a component.
 */
export const store: ILoginStore = createForState(storeState);

export function provideStore (app?: App<Element>): void {
  if (app !== undefined) {
    app.provide(LoginStoreKey, storeState);
  } else {
    provide(LoginStoreKey, storeState);
  }
}

export function useStore (): ILoginStore {
  const state = inject<ILoginState>(LoginStoreKey);
  if (state === undefined) {
    throw new Error('Using LoginStore before providing it!');
  }
  return createForState(state);
}
