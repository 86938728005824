export function isNullOrWhitespace<V = string> (value: V | null | undefined): boolean {
  return value === null || value === undefined || `${value}`.trim() === '';
}

export function cleanFileName (value: string | null | undefined): string | null | undefined {
  if (value === null || value === undefined || value.length <= 0) {
    return value;
  }
  const fileExtension = value.split('.').pop() ?? '';
  return encodeURIComponent(`${value.substring(0, value.length - fileExtension.length).replace(/\W/g, '')}.${fileExtension}`);
}

export function upperFirstLetter (value: string | null | undefined): string | null | undefined {
  if (!value) {
    return value;
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
}
