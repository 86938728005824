import {
  ITransactionHistoryReviewRequestModel,
  ITransactionHistoryGridRequestModel,
  ITransactionHistoryGridResponseModel,
  ITransactionHistoryExportRequestModel,
  ITransactionHistoryExportResponseModel,
  ITransactionsState,
  ITransactionsStore,
  TSetTotalRecords,
  TSetGridSorts,
  TSetGridColumns,
  TLoadRecords,
  TExportRecords,
  TReviewTransaction,
  TReset,
  TransactionLogHelpfulnessRatingType,
  TSetYesReviewCount,
  TSetNoReviewCount,
  TSetMaybeReviewCount,
  TSetBrowserTimeZoneFriendly,
  TSetGridData,
  TSetBrowserTimeZoneOffset,
  TGetGridRecords,
  IFilterChip,
  TSetDttmFilter,
  TSetGridLoadCancelToken,
  ITransactionHistoryRecord,
  TGetQuickFilter,
  TApplyQuickFilter,
  IGridQuickFilter,
  IGridColumnValuesRequestModel,
  IGridColumnValuesResponseModel,
  TLoadQuickFilter,
  TSetGridQuickFilters,
  TRemoveFilter,
  TSetUsage,
  TLoadUsage,
  ITransactionHistoryUsageRequestModel,
  TSetUsageLoadCancelToken,
  ITransactionHistoryUsageResponseModel,
  TCancelGridLoad,
  TCancelUsageLoad
} from '@/store/contracts/transactions';
import { impl } from '@/utils/impl';
import { provide, inject, reactive, App, readonly } from 'vue';
import { handleErrorStatusResult, IStatusHandler, ResponseStatus } from '@/store/utils/apiResponseHandling';
import { sendRequest } from '@/store/utils/axiosUtils';
import { setLoginLogoutListener } from '@/store/contracts/loginStore';
import { mapSorts, mapColumns, mapFilterChips, mapQuickFilterResponse, mapQuickFilters, columnDisplayName } from '@/store/mapping/transactionsMapping';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { Settings } from '@/settings';
import { GridColumnProps } from '@progress/kendo-vue-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import Axios from 'axios';
import { upperFirstLetter } from '@/utils/stringUtils';

const TransactionsStoreKey = Symbol('TransactionsStore');

const defaultTimezone = moment.tz(momentTimezone.tz.guess());
const defaultTimezoneFriendly = defaultTimezone.zoneAbbr();
const defaultSorts: SortDescriptor[] = [{
  field: 'requestDttm',
  dir: 'desc'
}];

const minColumnWidth = 86;
const defaultColumns: GridColumnProps[] = [
  { field: 'referenceId', title: columnDisplayName('referenceId'), width: '150px', columnMenu: false, type: 'string', cell: 'cellTemplate', minResizableWidth: minColumnWidth },
  { field: 'requestDttm', title: columnDisplayName('requestDttm', defaultTimezoneFriendly), width: '200px', columnMenu: 'dateFilterTemplate', filter: 'date', type: 'date', sortable: true, cell: 'dateCellTemplate', minResizableWidth: minColumnWidth },
  { field: 'source', title: columnDisplayName('source'), width: '200px', columnMenu: false, type: 'string', cell: 'cellTemplate', minResizableWidth: minColumnWidth },
  { field: 'requestType', title: columnDisplayName('requestType'), width: '170px', columnMenu: 'distinctFilterTemplate', type: 'string', cell: 'cellTemplate', minResizableWidth: minColumnWidth },
  { field: 'sourceLanguage', title: columnDisplayName('sourceLanguage'), width: '230px', columnMenu: false, type: 'string', sortable: true, cell: 'cellTemplate', minResizableWidth: minColumnWidth },
  { field: 'destinationLanguage', title: columnDisplayName('destinationLanguage'), width: '230px', columnMenu: false, type: 'string', cell: 'cellTemplate', minResizableWidth: minColumnWidth },
  { field: 'requestingUserFullName', title: columnDisplayName('requestingUserFullName'), width: '170px', columnMenu: 'distinctFilterTemplate', type: 'string', cell: 'cellTemplate', minResizableWidth: minColumnWidth },
  { field: 'requestingUserDivision', title: columnDisplayName('requestingUserDivision'), width: '200px', columnMenu: 'distinctFilterTemplate', type: 'string', cell: 'cellTemplate', minResizableWidth: minColumnWidth },
  { field: 'requestingUserOffice', title: columnDisplayName('requestingUserOffice'), width: '200px', columnMenu: 'distinctFilterTemplate', type: 'string', cell: 'cellTemplate', minResizableWidth: minColumnWidth },
  { field: 'resultDescription', title: columnDisplayName('resultDescription'), width: '200px', columnMenu: false, type: 'string', cell: 'cellTemplate', minResizableWidth: minColumnWidth },
  { field: 'helpfulnessRating', title: columnDisplayName('helpfulnessRating'), width: '190px', columnMenu: 'distinctFilterTemplate', type: 'string', cell: 'cellTemplate', minResizableWidth: minColumnWidth }
];

const createState = (): ITransactionsState => {
  const today = new Date();
  today.setHours(23, 59, 59, 99);
  const minDttm = new Date(new Date().setDate(today.getDate() - Settings.defaultTransactionDaysAgo));
  minDttm.setHours(0, 0, 0, 0);
  const utcOffset = defaultTimezone.utcOffset();

  return reactive(impl<ITransactionsState>({
    dttmFilter: {
      minDttm: minDttm,
      maxDttm: today,
      isExclude: false
    },
    gridLoadCancelToken: null,
    gridData: {
      gridItems: [],
      loadedOffset: 0,
      maxLoadedIndex: 0
    },
    totalRecords: 0,
    yesReviewCount: 0,
    maybeReviewCount: 0,
    noReviewCount: 0,
    browserTimeZoneFriendly: defaultTimezoneFriendly,
    browserTimeZoneOffset: utcOffset,
    gridSorts: defaultSorts,
    gridColumns: defaultColumns,
    gridQuickFilters: {},
    usageLoadCancelToken: null,
    usage: null
  }));
};

function setDttmFilter (state: ITransactionsState): TSetDttmFilter {
  return (minDttm, maxDttm, isExclude) => {
    state.dttmFilter.minDttm = minDttm;
    state.dttmFilter.maxDttm = maxDttm;
    state.dttmFilter.isExclude = isExclude;
  };
}

function setGridLoadCancelToken (state: ITransactionsState): TSetGridLoadCancelToken {
  return (gridLoadCancelToken) => {
    state.gridLoadCancelToken = gridLoadCancelToken;
  };
}

function setGridData (state: ITransactionsState): TSetGridData {
  return (gridItems, loadedOffset, maxLoadedIndex) => {
    state.gridData.gridItems = gridItems;
    state.gridData.loadedOffset = loadedOffset;
    state.gridData.maxLoadedIndex = maxLoadedIndex;
  };
}

function setTotalRecords (state: ITransactionsState): TSetTotalRecords {
  return (totalRecords) => {
    state.totalRecords = totalRecords;
  };
}

function setYesReviewCount (state: ITransactionsState): TSetYesReviewCount {
  return (yesReviewCount) => {
    state.yesReviewCount = yesReviewCount;
  };
}

function setMaybeReviewCount (state: ITransactionsState): TSetMaybeReviewCount {
  return (maybeReviewCount) => {
    state.maybeReviewCount = maybeReviewCount;
  };
}

function setNoReviewCount (state: ITransactionsState): TSetNoReviewCount {
  return (noReviewCount) => {
    state.noReviewCount = noReviewCount;
  };
}

function setBrowserTimeZoneFriendly (state: ITransactionsState): TSetBrowserTimeZoneFriendly {
  return (browserTimeZoneFriendly) => {
    state.browserTimeZoneFriendly = browserTimeZoneFriendly;
  };
}

function setBrowserTimeZoneOffset (state: ITransactionsState): TSetBrowserTimeZoneOffset {
  return (browserTimeZoneOffset) => {
    state.browserTimeZoneOffset = browserTimeZoneOffset;
  };
}

function setGridSorts (state: ITransactionsState): TSetGridSorts {
  return (sorts) => {
    state.gridSorts = sorts;
  };
}

function setGridColumns (state: ITransactionsState): TSetGridColumns {
  return (columns) => {
    state.gridColumns = columns;
  };
}

function setGridQuickFilters (state: ITransactionsState): TSetGridQuickFilters {
  return (gridQuickFilters) => {
    state.gridQuickFilters = gridQuickFilters;
  };
}

function setUsageLoadCancelToken (state: ITransactionsState): TSetUsageLoadCancelToken {
  return (usageLoadCancelToken) => {
    state.usageLoadCancelToken = usageLoadCancelToken;
  };
}

function setUsage (state: ITransactionsState): TSetUsage {
  return (usage) => {
    state.usage = usage;
  };
}

function loadRecords (state: ITransactionsState): TLoadRecords {
  return async (offset: number, limit: number, fullRefresh: boolean) => {
    const needsMoreRecords = fullRefresh || (state.gridLoadCancelToken === null && (offset < state.gridData.loadedOffset || Math.min(offset + limit, state.totalRecords) > state.gridData.maxLoadedIndex));

    if (!needsMoreRecords) {
      return;
    }

    if (state.gridLoadCancelToken !== null) {
      state.gridLoadCancelToken.cancel();
    }

    const cancelTokenSource = Axios.CancelToken.source();
    const cancelToken = cancelTokenSource.token;
    setGridLoadCancelToken(state)(cancelTokenSource);

    // Load records with a buffer on each side for a mini browser cache here in the store.
    const loadOffset = Math.max(offset - (limit * ~~(Settings.transactionsGridBuffer / 2)), 0);
    const loadLimit = limit * Settings.transactionsGridBuffer;

    const request: ITransactionHistoryGridRequestModel = {
      offset: loadOffset,
      limit: loadLimit,
      minRequestDttm: state.dttmFilter.minDttm,
      maxRequestDttm: state.dttmFilter.maxDttm,
      isDttmFilterExclude: state.dttmFilter.isExclude,
      sorts: mapSorts(state.gridSorts),
      filters: mapQuickFilters(Object.keys(state.gridQuickFilters).map(columnName => state.gridQuickFilters[columnName]))
    };
    const handler = impl<Partial<IStatusHandler<ITransactionHistoryGridResponseModel>>>({
      onSuccess: async data => {
        setTotalRecords(state)(data.totalRecords);
        setYesReviewCount(state)(data.yesReviewCount);
        setMaybeReviewCount(state)(data.maybeReviewCount);
        setNoReviewCount(state)(data.noReviewCount);
        setGridData(state)(data.records, loadOffset, loadOffset + data.records.length);
        setGridLoadCancelToken(state)(null);
      },
      onCancel: async (_) => {
        // Cancel will happen when chaining reloads, nothing to do here.
      }
    });

    await handleErrorStatusResult(await sendRequest<ITransactionHistoryGridResponseModel>(
      '/api/v1/precog/transactionHistory/grid',
      'post',
      request,
      cancelToken
    ), handler);
  };
}

function getGridRecords (): TGetGridRecords {
  return (offset: number, limit: number, loadedOffset: number, gridItems: readonly ITransactionHistoryRecord[]) => {
    const start = offset - loadedOffset;
    return gridItems.slice(start, start + limit);
  };
}

function exportRecords (state: ITransactionsState): TExportRecords {
  return async () => {
    const request: ITransactionHistoryExportRequestModel = {
      minRequestDttm: state.dttmFilter.minDttm,
      maxRequestDttm: state.dttmFilter.maxDttm,
      isDttmFilterExclude: state.dttmFilter.isExclude,
      sorts: mapSorts(state.gridSorts),
      filters: mapQuickFilters(Object.keys(state.gridQuickFilters).map(columnName => state.gridQuickFilters[columnName])),
      columnNames: mapColumns(state.gridColumns),
      clientTimeZoneFriendly: state.browserTimeZoneFriendly,
      clientTimeZoneOffset: state.browserTimeZoneOffset
    };
    let response: ITransactionHistoryExportResponseModel | null = null;
    const handler = impl<Partial<IStatusHandler<ITransactionHistoryExportResponseModel>>>({
      onSuccess: async data => {
        response = data;
      },
      onError: async _ => {
        response = {
          success: false,
          exportLocation: null,
          errorMessage: 'Error during export.'
        };
      }
    });
    await handleErrorStatusResult(await sendRequest<ITransactionHistoryExportResponseModel>(
      '/api/v1/precog/transactionHistory/export',
      'post',
      request
    ), handler);

    return response;
  };
}

function reviewTransaction (): TReviewTransaction {
  return async (transactionLogId: number, helpfulnessRating: TransactionLogHelpfulnessRatingType) => {
    const request: ITransactionHistoryReviewRequestModel = {
      transactionLogId: transactionLogId,
      helpfulnessRating: helpfulnessRating
    };
    await handleErrorStatusResult(await sendRequest<ResponseStatus.Success>(
      '/api/v1/precog/transactionHistory/review',
      'post',
      request
    ));
  };
}

function loadQuickFilter (state: ITransactionsState): TLoadQuickFilter {
  return async (columnName: string) => {
    const existingQuickFilter = state.gridQuickFilters[columnName];
    if (existingQuickFilter) {
      return;
    }

    const request: IGridColumnValuesRequestModel = {
      columnName: upperFirstLetter(columnName)!
    };

    let response: IGridColumnValuesResponseModel | null = null;
    const handler = impl<Partial<IStatusHandler<IGridColumnValuesResponseModel>>>({
      onSuccess: async data => {
        response = data;
      },
      onError: async _ => {
        response = null;
      }
    });
    await handleErrorStatusResult(await sendRequest<IGridColumnValuesResponseModel>(
      '/api/v1/precog/transactionHistory/columnValues',
      'post',
      request
    ), handler);

    const quickFilter = mapQuickFilterResponse(columnName, response);
    setGridQuickFilters(state)({ ...state.gridQuickFilters, [columnName]: quickFilter });
  };
}

function getQuickFilter (state: ITransactionsState): TGetQuickFilter {
  return (columnName: string) => {
    return state.gridQuickFilters[columnName];
  };
}

function applyQuickFilter (state: ITransactionsState): TApplyQuickFilter {
  return (columnName: string, quickFilter: IGridQuickFilter) => {
    state.gridQuickFilters[columnName] = quickFilter;
  };
}

function removeFilter (state: ITransactionsState): TRemoveFilter {
  return (columnName: string, value: string | null) => {
    if (columnName === 'requestDttm') {
      setDttmFilter(state)(null, null, false);
      return;
    }

    const quickFilter = getQuickFilter(state)(columnName);
    if (!quickFilter) {
      return;
    }

    const filterValue = quickFilter.values.find(v => v.value === value);
    if (!filterValue) {
      return;
    }
    filterValue.isSelected = false;
    if (quickFilter.values.every(v => !v.isSelected)) {
      quickFilter.isExclude = false;
    }
  };
}

function loadUsage (state: ITransactionsState): TLoadUsage {
  return async () => {
    if (state.usageLoadCancelToken !== null) {
      state.usageLoadCancelToken.cancel();
    }

    const cancelTokenSource = Axios.CancelToken.source();
    const cancelToken = cancelTokenSource.token;
    setUsageLoadCancelToken(state)(cancelTokenSource);

    const request: ITransactionHistoryUsageRequestModel = {
      minRequestDttm: state.dttmFilter.minDttm,
      maxRequestDttm: state.dttmFilter.maxDttm,
      isDttmFilterExclude: state.dttmFilter.isExclude,
      filters: mapQuickFilters(Object.keys(state.gridQuickFilters).map(columnName => state.gridQuickFilters[columnName]))
    };

    const handler = impl<Partial<IStatusHandler<ITransactionHistoryUsageResponseModel>>>({
      onSuccess: async data => {
        data.minRecordDttm = new Date(data.minRecordDttm);
        data.maxRecordDttm = new Date(data.maxRecordDttm);
        setUsage(state)(data);
        setUsageLoadCancelToken(state)(null);
      },
      onCancel: async (_) => {
        // Cancel will happen when chaining reloads, nothing to do here.
      }
    });

    await handleErrorStatusResult(await sendRequest<ITransactionHistoryUsageResponseModel>(
      '/api/v1/precog/transactionHistory/usage',
      'post',
      request,
      cancelToken
    ), handler);
  };
}

function cancelGridLoad (state: ITransactionsState): TCancelGridLoad {
  return () => {
    if (state.gridLoadCancelToken !== null) {
      state.gridLoadCancelToken.cancel();
    }
  };
}

function cancelUsageLoad (state: ITransactionsState): TCancelUsageLoad {
  return () => {
    if (state.usageLoadCancelToken !== null) {
      state.usageLoadCancelToken.cancel();
    }
  };
}

function reset (state: ITransactionsState): TReset {
  const today = new Date();
  today.setHours(23, 59, 59, 99);
  const minDttm = new Date(new Date().setDate(today.getDate() - Settings.defaultTransactionDaysAgo));
  minDttm.setHours(0, 0, 0, 0);

  return () => {
    if (state.gridLoadCancelToken !== null) {
      state.gridLoadCancelToken.cancel();
    }
    setDttmFilter(state)(minDttm, today, false);
    setGridLoadCancelToken(state)(null);
    setGridData(state)([], 0, 0);
    setTotalRecords(state)(0);
    setYesReviewCount(state)(0);
    setMaybeReviewCount(state)(0);
    setNoReviewCount(state)(0);
    setGridSorts(state)(defaultSorts);
    setGridColumns(state)(defaultColumns);
    setGridQuickFilters(state)({});
    setUsageLoadCancelToken(state)(null);
    setUsage(state)(null);
  };
}

function createForState (state: ITransactionsState): ITransactionsStore {
  return {
    state: readonly(state),
    setDttmFilter: setDttmFilter(state),
    setGridLoadCancelToken: setGridLoadCancelToken(state),
    setGridData: setGridData(state),
    setTotalRecords: setTotalRecords(state),
    setYesReviewCount: setYesReviewCount(state),
    setMaybeReviewCount: setMaybeReviewCount(state),
    setNoReviewCount: setNoReviewCount(state),
    setBrowserTimeZoneFriendly: setBrowserTimeZoneFriendly(state),
    setBrowserTimeZoneOffset: setBrowserTimeZoneOffset(state),
    setGridSorts: setGridSorts(state),
    setGridColumns: setGridColumns(state),
    setGridQuickFilters: setGridQuickFilters(state),
    setUsageLoadCancelToken: setUsageLoadCancelToken(state),
    setUsage: setUsage(state),
    loadRecords: loadRecords(state),
    getGridRecords: getGridRecords(),
    exportRecords: exportRecords(state),
    reviewTransaction: reviewTransaction(),
    loadQuickFilter: loadQuickFilter(state),
    getQuickFilter: getQuickFilter(state),
    applyQuickFilter: applyQuickFilter(state),
    removeFilter: removeFilter(state),
    loadUsage: loadUsage(state),
    cancelGridLoad: cancelGridLoad(state),
    cancelUsageLoad: cancelUsageLoad(state),
    reset: reset(state),
    get filterChips (): IFilterChip[] {
      return mapFilterChips(state.dttmFilter, Object.keys(state.gridQuickFilters).map(columnName => state.gridQuickFilters[columnName]));
    },
    get isLoadingGrid (): boolean {
      return state.gridLoadCancelToken !== null;
    },
    get isLoadingUsage (): boolean {
      return state.usageLoadCancelToken !== null;
    }
  };
}

export function provideStore (app?: App<Element>): void {
  const state = createState();
  const onLoginlogout = async () => createForState(state).reset();
  setLoginLogoutListener(
    TransactionsStoreKey.toString(),
    onLoginlogout,
    onLoginlogout);
  if (app !== undefined) {
    app.provide(TransactionsStoreKey, state);
  } else {
    provide(TransactionsStoreKey, state);
  }
}

export function useStore (): ITransactionsStore {
  const state = inject<ITransactionsState>(TransactionsStoreKey);
  if (state === undefined) {
    throw new Error('Using TransactionsStore before providing it!');
  }
  return createForState(state);
}
