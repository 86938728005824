import Axios from 'axios';
import { impl } from '@/utils/impl';

export interface ISettings {
  appVersion: string;
  allowVision: boolean;
  allowSelfRegister: boolean;
  defaultTransactionDaysAgo: number;
  transactionsGridBuffer: number;
  customTranscriptionFileExtensions: string | null;
  maxConcurrentUploads: number;
}

interface ISettingsModel {
  allowVision: boolean;
  allowSelfRegister: boolean;
  defaultTransactionDaysAgo: number;
  transactionsGridBuffer: number;
  customTranscriptionFileExtensions: string | null;
  maxConcurrentUploads: number;
}

export async function loadConfiguration (): Promise<void> {
  const requestUrl = '/api/v1/config/client';
  const configuration = await Axios.get<ISettingsModel>(requestUrl);
  Settings = impl<ISettings>({
    appVersion: Settings.appVersion,
    allowVision: configuration.data.allowVision,
    allowSelfRegister: configuration.data.allowSelfRegister,
    defaultTransactionDaysAgo: configuration.data.defaultTransactionDaysAgo,
    transactionsGridBuffer: configuration.data.transactionsGridBuffer,
    customTranscriptionFileExtensions: configuration.data.customTranscriptionFileExtensions,
    maxConcurrentUploads: configuration.data.maxConcurrentUploads
  });
}

export let Settings: ISettings = {
  appVersion: process.env.VUE_APP_VERSION,
  allowVision: process.env.VUE_APP_ALLOW_VISION === 'Y',
  allowSelfRegister: process.env.VUE_APP_ALLOW_SELF_REGISTER === 'Y',
  defaultTransactionDaysAgo: Number.parseInt(process.env.VUE_APP_DEFAULT_TRANSACTION_DAYS),
  transactionsGridBuffer: Number.parseInt(process.env.VUE_APP_TRANSACTION_GRID_BUFFER),
  customTranscriptionFileExtensions: null,
  maxConcurrentUploads: Number.parseInt(process.env.VUE_APP_MAX_CONCURRENT_UPLOADS)
};
