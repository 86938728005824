import {
  IModelValue,
  IValidationState,
  IValidationStore,
  TFieldErrorMessage,
  TFieldIsError,
  TFieldResult,
  TModelResults,
  TIsValid,
  TSetResults,
  TSetModelFieldResult,
  TDoValidations,
  TDoValidation,
  TDoFieldsValidation,
  IModelFieldValue,
  TClear,
  TReset
} from '@/store/contracts/validation';
import { impl } from '@/utils/impl';
import { IIndexable } from '@/utils/indexable';
import { ValidationModel } from '@/validation';
import { App, inject, provide, reactive, readonly } from 'vue';
import { setLoginLogoutListener } from '@/store/contracts/loginStore';

const ValidationStoreKey = Symbol('ValidationStore');

const createState = () => reactive(impl<IValidationState>({
  results: {}
}));

function modelResults (state: IValidationState): TModelResults {
  return (modelName: string) => state.results[modelName] ?? {};
}

function fieldResult (state: IValidationState): TFieldResult {
  return (modelName: string, fieldKey: string) =>
    modelResults(state)(modelName)[fieldKey] ?? true;
}

function fieldIsError (state: IValidationState): TFieldIsError {
  return (modelName: string, fieldKey: string) =>
    fieldResult(state)(modelName, fieldKey) !== true;
}

function fieldErrorMessage (state: IValidationState): TFieldErrorMessage {
  return (modelName: string, fieldKey: string) => {
    const result = fieldResult(state)(modelName, fieldKey);
    return result === true ? '' : result;
  };
}

function isValid (state: IValidationState): TIsValid {
  return (model: ValidationModel<any>, checkSubModels?: boolean) => {
    const checkSubModelsValue = checkSubModels ?? true;
    const startsWithCheck = `${model.modelName}:`;
    const subModels = Object.keys(state.results)
      .filter((k) => k.startsWith(startsWithCheck) && checkSubModelsValue);
    return [model.modelName, ...subModels].flatMap((modelName) => {
      return Object.keys(state.results[modelName] ?? {})
        .map((fieldKey) => fieldIsError(state)(modelName, fieldKey));
    }).none((isError) => isError);
  };
}

function setResults (state: IValidationState): TSetResults {
  return (results: IIndexable<IIndexable<true | string>>) => {
    state.results = results;
  };
}

function setModelFieldResult (state: IValidationState): TSetModelFieldResult {
  return (modelName: string, fieldKey: string, value: string | true) => {
    if (state.results[modelName] === undefined) {
      state.results[modelName] = {};
    }
    state.results[modelName][fieldKey] = value;
  };
}

function areValid (state: IValidationState) : (models: Array<ValidationModel<any>>, checkSubModels?: boolean) => boolean {
  return (models: Array<ValidationModel<any>>, checkSubModels?: boolean) =>
    models.reduce(
      (acc: boolean, curr) => acc && isValid(state)(curr, checkSubModels),
      true);
}

async function validateModel (
  dataGetter: () => IIndexable<IIndexable<true | string>>,
  { model, value }: IModelValue)
    : Promise<IIndexable<IIndexable<true | string>>> {
  const result = await model.validate(value);
  const data = dataGetter();
  return {
    ...data,
    [model.modelName]: {
      ...data[model.modelName],
      ...result
    }
  };
}

async function validateModelField (
  dataGetter: () => IIndexable<IIndexable<true | string>>,
  field: string,
  { model, value }: IModelValue)
    : Promise<IIndexable<IIndexable<true | string>>> {
  const result = await model.validateField(field, value);
  const data = dataGetter();
  return {
    ...data,
    [model.modelName]: {
      ...(data[model.modelName] ?? {}),
      [field]: result
    }
  };
}

function doValidations (state: IValidationState): TDoValidations {
  return async (models: IModelValue[]) => {
    const newResults = await (models.reduce(
      async (acc, curr) => {
        const results = await acc;
        setResults(state)(results);
        return validateModel(() => state.results, curr);
      },
      Promise.resolve(state.results)));
    setResults(state)(newResults);
    return areValid(state)(
      models.map(({ model }: IModelValue) => model),
      true);
  };
}

function doValidation (state: IValidationState): TDoValidation {
  return <T>(modelValue: IModelValue<T>) => doValidations(state)([modelValue]);
}

function doFieldsValidation (state: IValidationState): TDoFieldsValidation {
  return async <T>({ model, fields, value }: IModelFieldValue<T>) => {
    const newResults = await (fields.reduce(
      async (acc, curr) => {
        const results = await acc;
        setResults(state)(results);
        return validateModelField(
          () => state.results,
          curr,
          { model, value });
      },
      Promise.resolve(state.results)));
    setResults(state)(newResults);
    return isValid(state)(model, true);
  };
}

function clear (state: IValidationState): TClear {
  return <V>(model: ValidationModel<V>) => {
    const startsWithCheck = `${model.modelName}:`;
    const subModels = Object.keys(state.results).filter((k) => k.startsWith(startsWithCheck));
    const newResults = {
      ...state.results,
      [model.modelName]: {}
    };
    subModels.forEach((subModel) => { newResults[subModel] = {}; });
    setResults(state)(newResults);
  };
}

function reset (state: IValidationState): TReset {
  return () => {
    setResults(state)({});
  };
}

const storeState = createState();

const createForState = (state: IValidationState) => impl<IValidationStore>({
  state: readonly(state),
  modelResults: modelResults(state),
  fieldResult: fieldResult(state),
  fieldIsError: fieldIsError(state),
  fieldErrorMessage: fieldErrorMessage(state),
  isValid: isValid(state),
  setResults: setResults(state),
  setModelFieldResult: setModelFieldResult(state),
  doValidation: doValidation(state),
  doValidations: doValidations(state),
  doFieldsValidation: doFieldsValidation(state),
  clear: clear(state),
  reset: reset(state)
});

export const store: IValidationStore = createForState(storeState);

export function provideStore (app?: App<Element>): void {
  const onLoginlogout = async () => store.reset();
  setLoginLogoutListener(
    ValidationStoreKey.toString(),
    onLoginlogout,
    onLoginlogout);
  if (app !== undefined) {
    app.provide(ValidationStoreKey, storeState);
  } else {
    provide(ValidationStoreKey, storeState);
  }
}

export function useStore (): IValidationStore {
  const state = inject<IValidationState>(ValidationStoreKey);
  if (state === undefined) {
    throw new Error('Using ValidationStore before providing it!');
  }
  return createForState(state);
}
