import { provide, inject, reactive, App, readonly } from 'vue';
import { IUploadState, IUploadStore, IUploadInformation, TUpload, TAbortUpload, TSetUploads, TCleanupSuccess, UploadState, TSetIsOpen, TSetRoutedNamespace, TReset, TSetStatusDisplay, TUploadCompleteCallback, TUploadUrlBuilder, TSetUploadState } from '@/store/contracts/upload';
import { impl } from '@/utils/impl';
import { isNullOrWhitespace } from '@/utils/stringUtils';
import Axios, { AxiosResponse } from 'axios';
import { setLoginLogoutListener } from '@/store/contracts/loginStore';
import { delay } from '@/utils/systemUtils';
import { Settings } from '@/settings';

const UploadStoreKey = Symbol('UploadStore');
// const Batch = false; // Just hard coding this hear so can include logic to batch if that ever speeds up.

const createState = () => reactive(impl<IUploadState>({
  uploads: {},
  isOpen: false,
  notify: false,
  routedNamespace: 'Transcribe',
  uploadId: 0
}));

function setUploads (state: IUploadState): TSetUploads {
  return (uploads) => {
    state.uploads = uploads;
  };
}

function setIsOpen (state: IUploadState): TSetIsOpen {
  return (isOpen) => {
    state.isOpen = isOpen;
    if (isOpen) {
      setNotify(state)(false);
    }
  };
}

function setNotify (state: IUploadState): TSetIsOpen {
  return (notify) => {
    state.notify = notify;
  };
}

function setRoutedNamespace (state: IUploadState): TSetRoutedNamespace {
  return (routedNamespace) => {
    state.routedNamespace = routedNamespace;
  };
}

function currentUploadingCount (state: IUploadState): number {
  let count = 0;
  Object.keys(state.uploads).forEach(namespace => {
    count += state.uploads[namespace].filter(u => u.uploadState === UploadState.uploading).length;
  });
  return count;
}

function upload (state: IUploadState): TUpload {
  return (namespace: string, url: string, files: File[], uploadComplete: TUploadCompleteCallback, urlBuilder?: TUploadUrlBuilder) => {
    // if (Batch) {
    //   sendRequest(state, namespace, url, files, uploadComplete);
    // } else {
    //   files.forEach(f => sendRequest(state, namespace, url, [f], uploadComplete));
    // }
    files.forEach(f => sendRequest(state, namespace, url, f, uploadComplete, urlBuilder));
  };
}

async function sendRequest (state: IUploadState, namespace: string, url: string, file: File, uploadComplete: TUploadCompleteCallback, urlBuilder?: TUploadUrlBuilder) {
  let cleanUrl = url;
  if (urlBuilder) {
    cleanUrl = urlBuilder(file, url);
  }

  if (isNullOrWhitespace(cleanUrl)) {
    console.error('uploadStore: invalid or no URL specified!!');
    return;
  }

  const cancelTokenSource = Axios.CancelToken.source();
  const cancelToken = cancelTokenSource.token;

  const uploads = [] as IUploadInformation[];
  uploads.push({
    id: state.uploadId++,
    fileName: file.name,
    xhr: null,
    cancelToken: cancelTokenSource,
    uploadState: UploadState.notStarted,
    cancelled: false
  });

  const setUploadState = (): TSetUploadState => {
    return (upload: IUploadInformation, requestState: UploadState) => {
      const stateNamespace = state.uploads[namespace];
      const stateUpload = stateNamespace.find(su => su.id === upload.id);
      if (!stateUpload) {
        return;
      }
      stateUpload.uploadState = requestState;

      if (state.routedNamespace === namespace) {
        state.uploads[namespace] = stateNamespace.filter(u => u.id !== upload.id);
        setNotify(state)(false);
        return;
      }

      if (!state.isOpen) {
        setNotify(state)(true);
      }
    };
  };

  const setStatusDisplay = (): TSetStatusDisplay => {
    return (upload: IUploadInformation, statusDisplay?: string) => {
      const stateUpload = state.uploads[namespace].find(u => u.id === upload.id);
      if (!stateUpload) {
        return;
      }
      stateUpload.statusDisplay = statusDisplay;
    };
  };

  const setAxiosResponse = (response: AxiosResponse, success: boolean) => {
    uploads.forEach(u => {
      const stateUpload = state.uploads[namespace].find(su => su.id === u.id);
      if (!stateUpload) {
        return;
      }
      stateUpload.xhr = response;
      if (success) {
        stateUpload.uploadState = UploadState.polling;
      }
    });
  };

  if (!state.uploads[namespace]) {
    setUploads(state)({ ...state.uploads, [namespace]: [] });
  }

  state.uploads[namespace].push(...uploads);

  while (currentUploadingCount(state) > Settings.maxConcurrentUploads) {
    await delay(1000);
  }
  let uploadFound = false;
  for (const upload of state.uploads[namespace].filter(su => uploads.map(u => u.id).includes(su.id))) {
    if (upload.cancelled) {
      // If it was cancelled while we were waiting, don't send the request.
      continue;
    }
    uploadFound = true;
    upload.uploadState = UploadState.uploading;
  }

  if (!uploadFound) {
    return;
  }

  const blob = new Blob([file]);

  Axios.post(cleanUrl, blob, {
    withCredentials: true,
    cancelToken: cancelToken,
    headers: {
      'Content-Type': blob.type
    }
  }).then(async (r) => {
    setAxiosResponse(r, true);
    await uploadComplete(uploads, setStatusDisplay(), setUploadState());
  }).catch((e) => {
    setAxiosResponse(e.response, false);
    uploads.forEach(u => {
      const stateUpload = state.uploads[namespace].find(su => su.id === u.id);
      if (!stateUpload) {
        return;
      }
      stateUpload.uploadState = UploadState.failed;
    });

    if (!state.isOpen) {
      setNotify(state)(true);
    }
  });

  setIsOpen(state)(true);
}

function abortUpload (state: IUploadState): TAbortUpload {
  return (namespace: string, upload: IUploadInformation) => {
    const myNamespace = state.uploads[namespace];
    const index = myNamespace.findIndex(u => u.id === upload.id);
    const namespaceUpload = myNamespace[index];
    namespaceUpload.cancelToken.cancel();
    namespaceUpload.cancelled = true;
    myNamespace.splice(index, 1);
  };
}

function cleanupSuccess (state: IUploadState): TCleanupSuccess {
  return (namespace) => {
    const myNamespace = state.uploads[namespace];
    if (!myNamespace) {
      return;
    }
    state.uploads[namespace] = myNamespace.filter(u => u.uploadState !== UploadState.success);
  };
}

function reset (state: IUploadState): TReset {
  return () => {
    setUploads(state)({});
    setIsOpen(state)(false);
    setNotify(state)(false);
    setRoutedNamespace(state)('Transcribe');
  };
}

function createForState (state: IUploadState): IUploadStore {
  return {
    state: readonly(state),
    upload: upload(state),
    abortUpload: abortUpload(state),
    setUploads: setUploads(state),
    setIsOpen: setIsOpen(state),
    setNotify: setNotify(state),
    setRoutedNamespace: setRoutedNamespace(state),
    cleanupSuccess: cleanupSuccess(state),
    reset: reset(state),
    get noUploads (): boolean {
      if (!state || !state.uploads) {
        return true;
      }
      return Object.keys(state.uploads).none(k => state.uploads[k].length > 0);
    }
  };
}

export function provideStore (app?: App<Element>): void {
  const state = createState();
  const onLoginlogout = async () => createForState(state).reset();
  setLoginLogoutListener(
    UploadStoreKey.toString(),
    onLoginlogout,
    onLoginlogout);
  if (app !== undefined) {
    app.provide(UploadStoreKey, state);
  } else {
    provide(UploadStoreKey, state);
  }
}

export function useStore (): IUploadStore {
  const state = inject<IUploadState>(UploadStoreKey);
  if (state === undefined) {
    throw new Error('Using UploadStore before providing it!');
  }
  return createForState(state);
}
