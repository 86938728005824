import { IForgotPasswordModel } from '@/store/contracts/users/forgotPassword';
import { ValidationModel } from '@/validation';
import {
  emailFormatRule,
  stringRequiredRule
} from '@/validation/common/rules';

export enum ForgotPasswordModelFieldName {
  Email = 'email'
}

class ForgotPasswordValidationModel extends ValidationModel<IForgotPasswordModel> {
  constructor () {
    super();

    this.setField(ForgotPasswordModelFieldName.Email, {
      getter: (model: IForgotPasswordModel | null) => model?.email ?? null,
      rules: [
        stringRequiredRule('Email is required'),
        emailFormatRule()
      ]
    });
  }

  public get modelName (): string {
    return 'UserRegister';
  }
}

export default new ForgotPasswordValidationModel();
