import { createApp } from 'vue';
import '@/utils/arrayExtensions';
import '@/utils/stringExtensions';
import '@/utils/fileListExtensions';
import App from '@/App.vue';
import router from '@/router';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from '@/quasar-user-options.js';

import { provideStore as provideLeftDrawerStore } from '@/store/leftDrawer';
import { provideStore as provideTranscribeStore } from '@/store/transcribe';
import { provideStore as provideTranslateStore } from '@/store/translate';
import { provideStore as provideImageAnalysisStore } from '@/store/imageAnalysis';
import { provideStore as provideTimeStore } from '@/store/time';
import { provideStore as provideUploadStore } from '@/store/upload';
import { provideStore as provideRegisterStore } from '@/store/users/register';
import { provideStore as provideLoginStore } from '@/store/login';
import { provideStore as provideValidationStore } from '@/store/validation';
import { provideStore as provideForgotPasswordStore } from '@/store/users/forgotPassword';
import { provideStore as provideTransactionsStore } from '@/store/transactions';
import { provideStore as provideDivisionsGridStore } from '@/store/users/divisionsGrid';

import { doRefreshSession, registerSessionRefreshAdapter } from '@/store/utils/axiosSessionRefreshAdapter';
import { registerGuards } from '@/router/guards';
import { loadConfiguration } from '@/settings';

registerGuards(router);

const app = createApp(App);

app.use(Quasar, quasarUserOptions);
app.use(router);

loadConfiguration().then(() => {
  provideTimeStore(app);
  provideLeftDrawerStore(app);
  provideTranscribeStore(app);
  provideTranslateStore(app);
  provideImageAnalysisStore(app);
  provideUploadStore(app);
  provideRegisterStore(app);
  provideForgotPasswordStore(app);
  provideLoginStore(app);
  provideValidationStore(app);
  provideTransactionsStore(app);
  provideDivisionsGridStore(app);

  doRefreshSession();
  registerSessionRefreshAdapter();

  app.mount('#app');
});
