import { IUploadInformation, IUploadStore } from '@/store/contracts/upload';
import { IIndexable } from '@/utils/indexable';
import { DeepReadonly } from 'vue';
import { ITransactionContext, TransactionLogHelpfulnessRatingType, TransactionLogRequestType } from '@/store/contracts/transactions';
import { ITextTranslationResponse } from '@/store/contracts/translate';

export interface ISupportedLocale {
  localeCode: string;
  name: string;
}

export interface ILocalesResponseModel {
    defaultLocaleCodes: string[];
    locales: ISupportedLocale[];
}

export interface ITranscriptionRequestModel {
    transcriptionId: string;
    transactionLogId: number;
}

export interface ITranscriptionsRequestModel {
    requests: ITranscriptionRequestModel[];
}

export interface ITranscriptonPhrase {
    speaker?: number;
    offsetMillis: number;
    transcriptionText: string;
    confidence: number;
    translationResponse: ITextTranslationResponse | null;
    detectedLanguage?: string;
}

export interface ITranscription {
    localeCode?: string;
    friendlyLocale?: string;
    durationMillis?: number;
    transcriptionText?: string;
    phrases?: ITranscriptonPhrase[];
    selectedTranslateLanguageCode: string | null;
    translatedLanguageCode: string | null;
    singleLocaleDetected: boolean;
    translationResponse: ITextTranslationResponse | null;
}

export enum TranscriptionStatusType {
    NotStarted,
    Running,
    Succeeded,
    Failed
}

export interface ITranscriptionStatus {
    transcriptionId: string;
    success: boolean;
    transcription?: ITranscription;
    status: TranscriptionStatusType;
    errorMessage?: string;
}

export interface ITranscriptionStatusResponseModel {
    statuses: ITranscriptionStatus[];
}

export interface ITranscriptionMetadata {
    success: boolean;
    id?: string;
    localeCode?: string;
    friendlyLocale?: string;
    uploadedFileUri?: string;
    originalFileVideo?: boolean;
    convertedFileUri?: string;
    transactionContext: ITransactionContext;
    fileName: string;
    errorMessage?: string;
}

export interface ITranscriptionResponse {
    metadata: ITranscriptionMetadata;
    status?: ITranscriptionStatus;
}

export interface ITranscribeState {
    responses: IIndexable<ITranscriptionResponse>;
    supportedLocales: ISupportedLocale[];
    selectedLocales: ISupportedLocale[];
    files?: FileList;
    activeUploads: IUploadInformation[];
    isPolling: boolean;
    autoTranslate: boolean;
    selectedAutoTranslateLanguageCode: string;
}

export type TSetResponses = (responses: IIndexable<ITranscriptionResponse>) => void;
export type TSetLocales = (locales: ISupportedLocale[]) => void;
export type TSetSelectedLocales = (selectedLocales: readonly ISupportedLocale[]) => void;
export type TSetFiles = (selectedFiles?: FileList) => void;
export type TSetActiveUploads = (activeUploads: IUploadInformation[]) => void;
export type TSetIsPolling = (isPolling: boolean) => void;
export type TSetAutoTranslate = (autoTranslate: boolean) => void;
export type TSetSelectedAutoTranslateLanguageCode = (selectedAutoTranslateLanguageCode: string) => void;
export type TTransactionReviewed = (helpfulnessRating: TransactionLogHelpfulnessRatingType, responseName: string, transactionType: TransactionLogRequestType) => void;
export type TLoadLocales = () => Promise<void>;
export type TTranslateResponse = (responseKey: string, languageCode: string | null) => Promise<void>;
export type TUpdateResponseLanguageCode = (responseKey: string, selectedLanguageCode: string) => void;
export type TUploadFiles = (uploadStore: IUploadStore) => void;
export type TTranslateLanguageCode = (responseKey: string) => string | null;
export type TRemoveResponse = (responseKey: string) => void;
export type TReset = () => void;

export interface ITranscribeStore {
    state: DeepReadonly<ITranscribeState>;
    setResponses: TSetResponses;
    setLocales: TSetLocales;
    setSelectedLocales: TSetSelectedLocales;
    setFiles: TSetFiles;
    setActiveUploads: TSetActiveUploads;
    setIsPolling: TSetIsPolling;
    setAutoTranslate: TSetAutoTranslate,
    setSelectedAutoTranslateLanguageCode: TSetSelectedAutoTranslateLanguageCode,
    transactionReviewed: TTransactionReviewed;
    loadLocales: TLoadLocales;
    translateResponse: TTranslateResponse;
    updateResponseLanguageCode: TUpdateResponseLanguageCode;
    uploadFiles: TUploadFiles;
    translateLanguageCode: TTranslateLanguageCode;
    removeResponse: TRemoveResponse;
    reset: TReset;
    readonly noSelectedLocales: boolean;
    readonly noSelectedAutoTranslateLanguage: boolean;
    readonly noFiles: boolean;
}
