import { IIndexable } from '@/utils/indexable';
import { DeepReadonly } from 'vue';
import { AxiosResponse, CancelTokenSource } from 'axios';

export enum UploadState {
  notStarted = 'notStarted',
  uploading = 'uploading',
  polling = 'polling',
  success = 'success',
  failed = 'failed'
}

export interface IUploadInformation {
  id: number;
  fileName: string;
  xhr: AxiosResponse | null;
  cancelToken: CancelTokenSource;
  uploadState: UploadState;
  statusDisplay?: string;
  cancelled: boolean;
}

export interface IUploadState {
  uploads: IIndexable<IUploadInformation[]>;
  isOpen: boolean;
  notify: boolean;
  routedNamespace: string;
  uploadId: number;
}

export type TSetStatusDisplay = (upload: IUploadInformation, statusDisplay?: string) => void;
export type TSetUploadState = (upload: IUploadInformation, uploadState: UploadState) => void;
export type TUploadCompleteCallback = (i: IUploadInformation[], setStatusDisplay: TSetStatusDisplay, setUploadState: TSetUploadState) => Promise<void>;
export type TUploadUrlBuilder = (file: File, baseUrl: string) => string;
export type TUpload = (namespace: string, url: string, files: File[], uploadComplete: TUploadCompleteCallback, urlBuilder?: TUploadUrlBuilder) => void;
export type TAbortUpload = (namespace: string, upload: IUploadInformation) => void;
export type TSetUploads = (uploads: IIndexable<IUploadInformation[]>) => void;
export type TSetIsOpen = (isOpen: boolean) => void;
export type TSetNotify = (notify: boolean) => void;
export type TSetRoutedNamespace = (routedNamespace: string) => void;
export type TCleanupSuccess = (namespace: string) => void;
export type TReset = () => void;

export interface IUploadStore {
  state: DeepReadonly<IUploadState>;
  upload: TUpload;
  abortUpload: TAbortUpload;
  setUploads: TSetUploads;
  setIsOpen: TSetIsOpen;
  setNotify: TSetNotify;
  setRoutedNamespace: TSetRoutedNamespace;
  cleanupSuccess: TCleanupSuccess;
  reset: TReset;
  readonly noUploads: boolean;
}
