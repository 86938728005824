import { DeepReadonly } from 'vue';

export enum DivisionOfficeGridItemType {
  division = 'division',
  office = 'office'
}

export interface IDivisionOfficeGridItem {
  type: DivisionOfficeGridItemType;
  divisionId: number;
  divisionName: string;
  officeName: string | null;
  officeId: number | null;
}

export interface IDivisionsGridState {
  gridItems: IDivisionOfficeGridItem[];
}

export interface IDivisionModel {
  id: number;
  name: string;
}

export interface IAddDivisionModel {
  divisionName: string;
  officeNames: string[];
}

export interface IOfficeModel {
  divisionId: number;
  officeId: number;
  name: string;
}

export interface IAddOfficeModel {
  divisionId: number;
  name: string;
}

export interface IApplyDivisionsRequestModel {
  addDivisions: IAddDivisionModel[];
  addOffices: IAddOfficeModel[];
  editDivisions: IDivisionModel[];
  editOffices: IOfficeModel[];
  removeDivisionIds: number[];
  removeOfficeIds: number[];
}

export type TSetGridItems = (gridItems: IDivisionOfficeGridItem[]) => void;
export type TLoadGridItems = () => Promise<void>;
export type TAddDivision = (divisionName: string) => Promise<void>;
export type TAddOffice = (officeName: string, divisionId: number) => Promise<void>;
export type TEditDivision = (divisionId: number, name: string) => Promise<void>;
export type TEditOffice = (officeId: number, name: string, divisionId: number) => Promise<void>;
export type TDeleteDivision = (divisionId: number) => Promise<void>;
export type TDeleteOffice = (officeId: number) => Promise<void>;

export interface IDivisionsGridStore {
  state: DeepReadonly<IDivisionsGridState>;
  setGridItems: TSetGridItems;
  loadGridItems: TLoadGridItems;
  addDivision: TAddDivision;
  addOffice: TAddOffice;
  editDivision: TEditDivision;
  editOffice: TEditOffice;
  deleteDivision: TDeleteDivision;
  deleteOffice: TDeleteOffice;
  readonly divisions: IDivisionOfficeGridItem[];
  readonly offices: IDivisionOfficeGridItem[];
}
