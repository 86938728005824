import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { HomeRoutes } from '@/router/routes/home';
import { LoginRoutes } from '@/router/routes/login';
import { ErrorRoutes } from '@/router/routes/error';
import { UsersRoutes } from '@/router/routes/users';
import { UserRole } from '@/store/contracts/loginStore';
import { passThrough, transformRouteParams } from '@/router/paramTransformer';
import { TransactionRoutes } from './routes/transactions';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    children: [
      {
        path: '',
        name: HomeRoutes.index,
        redirect: { name: HomeRoutes.transcribe }
      },
      {
        path: '/translate',
        name: HomeRoutes.translate,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/Translate.vue'),
        meta: {
          authorizedRoles: [UserRole.User, UserRole.Admin]
        }
      },
      {
        path: '/transcribe',
        name: HomeRoutes.transcribe,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/Transcribe.vue'),
        meta: {
          authorizedRoles: [UserRole.User, UserRole.Admin]
        }
      },
      {
        path: '/analyze',
        name: HomeRoutes.analyze,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/home/ImageAnalysis.vue'),
        meta: {
          authorizedRoles: [UserRole.Vision]
        }
      }
    ]
  },
  {
    path: '/users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/Admin.vue'),
    children: [
      {
        path: '',
        name: UsersRoutes.index,
        redirect: { name: UsersRoutes.home }
      },
      {
        path: '/list',
        name: UsersRoutes.home,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UserHome.vue'),
        meta: {
          authorizedRoles: [UserRole.Admin]
        }
      },
      {
        path: '/view/:userId',
        name: UsersRoutes.view,
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UserView.vue'),
        meta: {
          authorizedRoles: [UserRole.Admin]
        },
        props: (route) => {
          return {
            ...transformRouteParams({
              userId: passThrough
            })(route)
          };
        }
      },
      {
        path: '/create',
        name: UsersRoutes.create,
        component: () => import(/* webpackChunkName: "users" */ '@/views/users/UserCreate.vue'),
        meta: {
          authorizedRoles: [UserRole.Admin]
        }
      }
    ]
  },
  {
    path: '/transactions',
    component: () => import(/* webpackChunkName: 'transactions' */ '@/views/Admin.vue'),
    children: [
      {
        path: '',
        name: TransactionRoutes.index,
        redirect: { name: TransactionRoutes.home }
      },
      {
        path: '/grid',
        name: TransactionRoutes.home,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "transactions" */ '@/views/transactions/TransactionsHome.vue'),
        meta: {
          authorizedRoles: [UserRole.Admin]
        }
      }
    ]
  },
  {
    path: '/login',
    name: LoginRoutes.login,
    component: () => import(/* webpackChunkName: 'login' */ '@/views/Login.vue')
  },
  {
    path: '/error',
    name: ErrorRoutes.error,
    component: () => import(/* webpackChunkName: 'error' */ '@/views/error/Error.vue')
  },
  {
    path: '/not-found',
    name: ErrorRoutes.notFound,
    component: () => import(/* webpackChunkName: 'not-found' */ '@/views/error/NotFound.vue')
  },
  {
    path: '/',
    redirect: { name: HomeRoutes.index }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: ErrorRoutes.notFound }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
