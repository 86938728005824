import { IUserRegisterModel } from '@/store/contracts/users/register';
import { ValidationModel } from '@/validation';
import {
  emailFormatRule,
  stringRequiredRule
} from '@/validation/common/rules';

export enum UserRegisterModelFieldName {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName'
}

class UserRegisterValidationModel extends ValidationModel<IUserRegisterModel> {
  constructor () {
    super();

    this.setField(UserRegisterModelFieldName.Email, {
      getter: (model: IUserRegisterModel | null) => model?.email ?? null,
      rules: [
        stringRequiredRule('Email is required'),
        emailFormatRule()
      ]
    });

    this.setField(UserRegisterModelFieldName.FirstName, {
      getter: (model: IUserRegisterModel | null) => model?.firstName ?? null,
      rules: [
        stringRequiredRule('First Name is required')
      ]
    });

    this.setField(UserRegisterModelFieldName.LastName, {
      getter: (model: IUserRegisterModel | null) => model?.lastName ?? null,
      rules: [
        stringRequiredRule('Last Name is required')
      ]
    });
  }

  public get modelName (): string {
    return 'UserRegister';
  }
}

export default new UserRegisterValidationModel();
