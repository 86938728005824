import { provide, inject, reactive, App, readonly } from 'vue';
import {
  ITranscribeState,
  ITranscribeStore,
  TSetResponses,
  ITranscriptionResponse,
  TSetLocales,
  TLoadLocales,
  TSetSelectedLocales,
  TTransactionReviewed,
  TTranslateResponse,
  TUpdateResponseLanguageCode,
  TSetFiles,
  TUploadFiles,
  TTranslateLanguageCode,
  TReset,
  TRemoveResponse,
  ITranscriptionMetadata,
  ITranscriptionRequestModel,
  ITranscriptionStatus,
  TranscriptionStatusType,
  ILocalesResponseModel,
  TSetActiveUploads,
  TSetIsPolling,
  ITranscriptionsRequestModel,
  TSetAutoTranslate,
  TSetSelectedAutoTranslateLanguageCode,
  ITranscriptionStatusResponseModel
} from '@/store/contracts/transcribe';
import { IUploadInformation, IUploadStore, TSetStatusDisplay, TSetUploadState, TUploadCompleteCallback, TUploadUrlBuilder, UploadState } from '@/store/contracts/upload';
import { impl } from '@/utils/impl';
import { cleanFileName, isNullOrWhitespace } from '@/utils/stringUtils';
import { setLoginLogoutListener } from '@/store/contracts/loginStore';
import { ITextTranslateRequest, ITextTranslationResponse } from '@/store/contracts/translate';
import { TransactionLogRequestType, TransactionLogHelpfulnessRatingType } from '@/store/contracts/transactions';
import { handleErrorStatusResult, IStatusHandler } from '@/store/utils/apiResponseHandling';
import { sendRequest } from '@/store/utils/axiosUtils';
import { delay } from '@/utils/systemUtils';

const TranscribeStoreKey = Symbol('TranscribeStore');

const maxTranscribeLocales = 10;

const createState = () => reactive(impl<ITranscribeState>({
  responses: {},
  supportedLocales: [],
  selectedLocales: [],
  files: undefined,
  activeUploads: [],
  isPolling: false,
  autoTranslate: true,
  selectedAutoTranslateLanguageCode: ''
}));

function setAutoTranslate (state: ITranscribeState): TSetAutoTranslate {
  return (autoTranslate) => {
    state.autoTranslate = autoTranslate;
  };
}

function setSelectedAutoTranslateLanguageCode (state: ITranscribeState): TSetSelectedAutoTranslateLanguageCode {
  return (selectedAutoTranslateLanguageCode) => {
    state.selectedAutoTranslateLanguageCode = selectedAutoTranslateLanguageCode;
  };
}

function setResponses (state: ITranscribeState): TSetResponses {
  return (responses) => {
    state.responses = responses;
  };
}

function setLocales (state: ITranscribeState): TSetLocales {
  return (locales) => {
    state.supportedLocales = locales;
  };
}

function setSelectedLocales (state: ITranscribeState): TSetSelectedLocales {
  return (selectedLocales) => {
    if (selectedLocales.length <= maxTranscribeLocales) {
      state.selectedLocales = selectedLocales.slice().sort();
    }
  };
}

function transactionReviewed (state: ITranscribeState): TTransactionReviewed {
  return (helpfulnessRating: TransactionLogHelpfulnessRatingType, responseName: string, transactionType: TransactionLogRequestType) => {
    switch (transactionType) {
      case TransactionLogRequestType.Transcription: {
        state.responses[responseName].metadata.transactionContext.helpfulnessRating = helpfulnessRating;
        break;
      }

      case TransactionLogRequestType.TranscriptionTranslation: {
        const translationResponse = state.responses[responseName].status?.transcription?.translationResponse;
        if (translationResponse) {
          translationResponse.transactionContext.helpfulnessRating = helpfulnessRating;
        }
        break;
      }
    }
  };
}

function setFiles (state: ITranscribeState): TSetFiles {
  return (files) => {
    state.files = files;
  };
}

function setActiveUploads (state: ITranscribeState): TSetActiveUploads {
  return (uploads) => {
    state.activeUploads = uploads;
  };
}

function setIsPolling (state: ITranscribeState): TSetIsPolling {
  return (isPolling) => {
    state.isPolling = isPolling;
  };
}

function setFileUploadUri (state: ITranscribeState): TUploadUrlBuilder {
  return (file: File, baseUrl: string) => {
    let cleanBaseUrl = baseUrl;
    if (!baseUrl.endsWith('/') && !baseUrl.endsWith('\\')) {
      cleanBaseUrl += '/';
    }
    const fileName = cleanFileName(file.name);

    const localesQueryPrefix = '&locales=';
    const locales = state.selectedLocales.map(s => localesQueryPrefix.concat(s.localeCode));

    return `${cleanBaseUrl}?&fileName=${fileName}${locales.reduce((a, b) => a.concat(b))}`;
  };
}

function uploadFiles (state: ITranscribeState): TUploadFiles {
  return (uploadStore: IUploadStore) => {
    if (!state.files || state.files.length === 0) {
      return;
    }
    uploadStore.upload('Transcribe', '/api/v1/precog/transcribe/', Array.from(state.files), onUploadSuccess(state), setFileUploadUri(state));
  };
}

function getFileKey (fileName: string, fileKeys: string[], iter?: number): string {
  const fileKey = iter === undefined ? fileName : `${fileName} (${iter})`;

  if (!fileKeys.includes(fileKey)) {
    return fileKey;
  }

  return getFileKey(fileName, fileKeys, iter === undefined ? 1 : iter + 1);
}

function isStatusDone (status?: ITranscriptionStatus): boolean {
  if (!status) {
    return false;
  }
  return status.status !== TranscriptionStatusType.Running && status.status !== TranscriptionStatusType.NotStarted;
}

function statusDisplay (status: TranscriptionStatusType): string | undefined {
  switch (status) {
    case TranscriptionStatusType.Succeeded:
    case TranscriptionStatusType.Failed:
      return undefined;
    case TranscriptionStatusType.NotStarted:
      return 'Queued';
    case TranscriptionStatusType.Running:
      return 'Running';
  }
}

function uploadComplete (state: ITranscribeState, upload: IUploadInformation): void {
  setActiveUploads(state)(state.activeUploads.filter(info => info.id !== upload.id));
}

async function pollActiveUploads (state: ITranscribeState, setStatusDisplay: TSetStatusDisplay, setUploadState: TSetUploadState): Promise<void> {
  if (state.isPolling) {
    return;
  }

  setIsPolling(state)(true);

  while (state.activeUploads.length > 0) {
    await delay(5000);

    const activeUploads = [...state.activeUploads];
    const requests: ITranscriptionRequestModel[] = [];
    const metadatas: ITranscriptionMetadata[] = [];
    for (const upload of activeUploads) {
      const mappedMetadata: ITranscriptionMetadata = upload.xhr?.data;
      metadatas.push(mappedMetadata);
      requests.push({
        transcriptionId: mappedMetadata.id ?? '',
        transactionLogId: mappedMetadata.transactionContext.transactionLogId
      });
    }

    const request: ITranscriptionsRequestModel = {
      requests: requests
    };

    let responseModel: ITranscriptionStatusResponseModel;

    const handler = impl<Partial<IStatusHandler<ITranscriptionStatusResponseModel>>>({
      onSuccess: async response => {
        responseModel = response;
        for (let i = 0; i < responseModel.statuses.length; i++) {
          const status = responseModel.statuses[i];
          const uploadInfo = activeUploads[i];
          if (uploadInfo.cancelled) {
            uploadComplete(state, uploadInfo);
            continue;
          }
          setStatusDisplay(uploadInfo, statusDisplay(status.status));
          if (status.success && status.status === TranscriptionStatusType.Succeeded && !!status.transcription) {
            status.transcription.selectedTranslateLanguageCode = null;
            status.transcription.translationResponse = null;

            if (status.transcription.phrases) {
              for (const phrase of status.transcription.phrases) {
                phrase.translationResponse = null;
              }
            }
          }
          const done = isStatusDone(status);
          if (done) {
            const fileKey = getFileKey(uploadInfo.fileName, Object.keys(state.responses));
            const transcriptionResponse: ITranscriptionResponse = {
              metadata: metadatas[i],
              status: status
            };
            setResponses(state)({ ...state.responses, [fileKey]: transcriptionResponse });

            // Extract language part from the locale code, i.e., get 'en' from 'en-US'
            const detectedLanguage = (transcriptionResponse.metadata.localeCode ?? transcriptionResponse.status?.transcription?.localeCode)?.split('-')[0];

            // Check if auto detect language code === transcription language code
            // Also, check if status.success === true, to ensure translation only if transcription was successful
            if (
              state.autoTranslate &&
              status.success &&
              detectedLanguage !== state.selectedAutoTranslateLanguageCode
            ) {
              await translateResponse(state)(fileKey, state.selectedAutoTranslateLanguageCode);
            }

            setUploadState(uploadInfo, status.success ? UploadState.success : UploadState.failed);
            uploadComplete(state, uploadInfo);
          }
        }
      },
      onError: async () => {
        for (let i = 0; i < activeUploads.length; i++) {
          const uploadInfo = activeUploads[i];
          if (uploadInfo.cancelled) {
            uploadComplete(state, uploadInfo);
            continue;
          }
          const failedFileKey = getFileKey(uploadInfo.fileName, Object.keys(state.responses));
          const failedTranscriptionResponse: ITranscriptionResponse = {
            metadata: metadatas[i],
            status: {
              transcriptionId: metadatas[i].id ?? '',
              success: false,
              status: TranscriptionStatusType.Failed,
              errorMessage: 'Error during transcription.'
            }
          };
          setResponses(state)({ ...state.responses, [failedFileKey]: failedTranscriptionResponse });
          setUploadState(uploadInfo, UploadState.failed);
          uploadComplete(state, uploadInfo);
        }
      },
      onCancel: async () => {
        // nothing to do here
      }
    });

    await handleErrorStatusResult(await sendRequest<ITranscriptionStatusResponseModel>(
      'api/v1/precog/transcription/status',
      'post',
      request
    ), handler);
  }

  setIsPolling(state)(false);
}

function onUploadSuccess (state: ITranscribeState): TUploadCompleteCallback {
  return (info: IUploadInformation[], setStatusDisplay: TSetStatusDisplay, setUploadState: TSetUploadState) => {
    const successUploadInfos: IUploadInformation[] = [];
    for (const uploadInfo of info) {
      if (uploadInfo.cancelled) {
        continue;
      }

      const mappedMetadata: ITranscriptionMetadata = uploadInfo.xhr?.data;
      const existingResponse = Object.values(state.responses).find(r => r.metadata.id === mappedMetadata.id);
      if (!!existingResponse || !mappedMetadata.success) {
        const failedFileKey = getFileKey(uploadInfo.fileName, Object.keys(state.responses));
        const failedResponse: ITranscriptionResponse = {
          metadata: mappedMetadata,
          status: {
            transcriptionId: mappedMetadata.id ?? '',
            success: false,
            status: TranscriptionStatusType.Failed,
            errorMessage: 'Error during transcription.'
          }
        };
        setResponses(state)({ ...state.responses, [failedFileKey]: failedResponse });
        setUploadState(uploadInfo, UploadState.failed);
        continue;
      }
      successUploadInfos.push(uploadInfo);
    }

    if (successUploadInfos.length === 0) {
      return Promise.resolve();
    }
    setActiveUploads(state)([...state.activeUploads, ...successUploadInfos]);
    pollActiveUploads(state, setStatusDisplay, setUploadState);
    return Promise.resolve();
  };
}

function loadLocales (state: ITranscribeState): TLoadLocales {
  return async () => {
    if (state.supportedLocales.length > 0) {
      return;
    }
    const handler = impl<Partial<IStatusHandler<ILocalesResponseModel>>>({
      onSuccess: async response => {
        setLocales(state)(response.locales);
        setSelectedLocales(state)(response.locales.filter(l => response.defaultLocaleCodes.includes(l.localeCode)));
      }
    });
    await handleErrorStatusResult(await sendRequest<ILocalesResponseModel>(
      '/api/v1/precog/locales',
      'get'
    ), handler);
  };
}

async function translateText (text: string, languageCode: string, requestType: TransactionLogRequestType, transcriptionTransactionLogId: number, transcriptionFileName: string): Promise<ITextTranslationResponse | null> {
  const fullTranslationRequest: ITextTranslateRequest = {
    toLanguageCode: languageCode,
    content: text,
    requestType: requestType,
    transcriptionTransactionLogId: transcriptionTransactionLogId,
    transcriptionFileName: transcriptionFileName
  };

  let translationResponse: ITextTranslationResponse | null = null;
  const handler = impl<Partial<IStatusHandler<ITextTranslationResponse>>>({
    onSuccess: async response => {
      translationResponse = response;
    }
  });
  await handleErrorStatusResult(await sendRequest<ITextTranslationResponse>(
    'api/v1/precog/translate/text',
    'post',
    fullTranslationRequest
  ), handler);

  return translationResponse;
}

function translateResponse (state: ITranscribeState): TTranslateResponse {
  return async (responseKey: string, languageCode: string | null) => {
    const responses = state.responses;
    const myResponse = responses[responseKey];
    if (!myResponse.status?.transcription) {
      return;
    }
    const transactionLogId = myResponse.metadata.transactionContext.transactionLogId;
    const transcriptionFileName = myResponse.metadata.fileName;
    const removeTranslation = !languageCode;
    myResponse.status.transcription.selectedTranslateLanguageCode = languageCode;
    myResponse.status.transcription.translationResponse = removeTranslation ? null : await translateText(myResponse.status.transcription.transcriptionText ?? '', languageCode!, TransactionLogRequestType.TranscriptionTranslation, transactionLogId, transcriptionFileName);
    myResponse.status.transcription.translatedLanguageCode = removeTranslation ? null : myResponse.status.transcription.selectedTranslateLanguageCode;

    if (!myResponse.status.transcription.phrases) {
      return;
    }
    for (const phrase of myResponse.status.transcription.phrases) {
      phrase.translationResponse = removeTranslation ? null : await translateText(phrase.transcriptionText, languageCode!, TransactionLogRequestType.TranscriptionPhraseTranslation, transactionLogId, transcriptionFileName);
    }
  };
}

function updateResponseLanguageCode (state: ITranscribeState) : TUpdateResponseLanguageCode {
  return (responseKey: string, selectedLanguageCode: string) => {
    const responses = state.responses;
    const myResponse = responses[responseKey];
    if (!myResponse.status?.transcription) {
      return;
    }
    myResponse.status.transcription.selectedTranslateLanguageCode = selectedLanguageCode;
  };
}

function translateLanguageCode (state: ITranscribeState): TTranslateLanguageCode {
  return (responseKey: string) => {
    const response = state.responses[responseKey];
    if (!response?.status?.transcription) {
      return null;
    }
    const translateCode = response.status.transcription.translatedLanguageCode;

    return translateCode;
  };
}

function removeResponse (state: ITranscribeState): TRemoveResponse {
  return (responseKey: string) => {
    delete state.responses[`${responseKey}`];
  };
}

function reset (state: ITranscribeState): TReset {
  return () => {
    setResponses(state)({});
    setLocales(state)([]);
    setFiles(state)(undefined);
    setSelectedLocales(state)([]);
    setAutoTranslate(state)(true);
    setSelectedAutoTranslateLanguageCode(state)('');
  };
}

function createForState (state: ITranscribeState): ITranscribeStore {
  return {
    state: readonly(state),
    setResponses: setResponses(state),
    setLocales: setLocales(state),
    setSelectedLocales: setSelectedLocales(state),
    transactionReviewed: transactionReviewed(state),
    setFiles: setFiles(state),
    setActiveUploads: setActiveUploads(state),
    setIsPolling: setIsPolling(state),
    setAutoTranslate: setAutoTranslate(state),
    setSelectedAutoTranslateLanguageCode: setSelectedAutoTranslateLanguageCode(state),
    loadLocales: loadLocales(state),
    translateResponse: translateResponse(state),
    updateResponseLanguageCode: updateResponseLanguageCode(state),
    uploadFiles: uploadFiles(state),
    translateLanguageCode: translateLanguageCode(state),
    removeResponse: removeResponse(state),
    reset: reset(state),
    get noSelectedLocales (): boolean {
      return !state || state.selectedLocales.length === 0;
    },
    get noSelectedAutoTranslateLanguage (): boolean {
      return !state || isNullOrWhitespace(state.selectedAutoTranslateLanguageCode);
    },
    get noFiles (): boolean {
      return !state || !state.files || state.files.length === 0;
    }
  };
}

export function provideStore (app?: App<Element>): void {
  const state = createState();
  const onLoginlogout = async () => createForState(state).reset();
  setLoginLogoutListener(
    TranscribeStoreKey.toString(),
    onLoginlogout,
    onLoginlogout);
  if (app !== undefined) {
    app.provide(TranscribeStoreKey, state);
  } else {
    provide(TranscribeStoreKey, state);
  }
}

export function useStore (): ITranscribeStore {
  const state = inject<ITranscribeState>(TranscribeStoreKey);
  if (state === undefined) {
    throw new Error('Using TranscribeStore before providing it!');
  }
  return createForState(state);
}
