import { DeepReadonly, reactive, readonly } from 'vue';
import { IStatusHandler } from '@/store/utils/apiResponseHandling';
import { IIndexable } from '@/utils/indexable';

export enum UserRole {
    User = 'User',
    Admin = 'Admin',
    Vision = 'Vision'
}

export enum LoginRequirement {
    None = 0,
    UserInformation = 1 << 0,
    PasswordReset = 1 << 1
}

export interface IUserInfo {
    id: string;
    loginRequirements: LoginRequirement;
    email: string;
    firstName: string;
    lastName: string;
    roles: UserRole[];
    officeId: number;
}

export interface ILoginState {
    info: IUserInfo | null;
}

export interface IPasswordLoginModel {
    email: string;
    password: string;
}

export type TSignOut = (handler?: Partial<IStatusHandler<void>>) => Promise<boolean>;
export type TSignIn = (model: IPasswordLoginModel, handler?: Partial<IStatusHandler<IUserInfo>>) => Promise<boolean>;
export type TSetUserInfo = (info: IUserInfo | null) => void;
export type TLoginRequirementsComplete = () => void;
export type THasRole = (role: UserRole) => boolean;
export type TIsLoggedIn = () => boolean;

export interface ILoginStore {
    state: DeepReadonly<ILoginState>;
    signOut: TSignOut;
    signIn: TSignIn;
    setUserInfo: TSetUserInfo;
    loginRequirementsComplete: TLoginRequirementsComplete;
    hasRole: THasRole;
    isLoggedIn: TIsLoggedIn;
    readonly userRoles: UserRole[];
}

export type TLogoutListener = () => Promise<void>;
export type TLoginListener = () => Promise<void>;

const logoutListeners: IIndexable<TLogoutListener> = reactive({});
const loginListeners: IIndexable<TLoginListener> = reactive({});

export function getLogoutListeners (): DeepReadonly<IIndexable<TLogoutListener>> {
  return readonly(logoutListeners);
}

export function setLogoutListener (key: string, listener: TLogoutListener): void {
  logoutListeners[key] = listener;
}

export function removeLogoutListener (key: string): void {
  delete logoutListeners[key];
}

export function getLoginListeners (): DeepReadonly<IIndexable<TLoginListener>> {
  return readonly(loginListeners);
}

export function setLoginListener (key: string, listener: TLoginListener): void {
  loginListeners[key] = listener;
}

export function removeLoginListener (key: string): void {
  delete loginListeners[key];
}

export function setLoginLogoutListener (key: string, loginListener: TLoginListener, logoutListener: TLogoutListener): void {
  setLoginListener(key, loginListener);
  setLogoutListener(key, logoutListener);
}

export function hasFlag (flags?: LoginRequirement, flag?: LoginRequirement): boolean {
  if (!flags || !flag) {
    return false;
  }
  return (flags & flag) === flag;
}
