import { IUploadInformation, IUploadStore } from '@/store/contracts/upload';
import { IIndexable } from '@/utils/indexable';
import { DeepReadonly } from 'vue';
import { ITransactionContext, TransactionLogRequestType, TransactionLogHelpfulnessRatingType } from '@/store/contracts/transactions';

export interface ISupportedLanguage {
    languageCode: string;
    name: string;
}

export interface ILanguagesResponseModel {
    languages: ISupportedLanguage[];
    defaultLanguageCode: string;
}

export interface ISupportedFileExtensions {
    fileExtensions: string[];
}

export enum TranslationType {
    Text,
    Document,
    TextExtraction,
}

export interface DocumentPageModel {
    pageNumber?: number;
    paragraphs: string[];
}

export interface ITranslationResponse {
    fileName?: string;
    success: boolean;
    type: TranslationType;
    transactionContext?: ITransactionContext;
    sourceText?: string,
    translatedText?: string;
    pages?: DocumentPageModel[];
    translatedDocumentSasUrl?: string;
    sourceLanguage?: string;
    isImage?: boolean;
    destinationLanguage: string;
    errorMessage?: string;
}

export interface ITextTranslateRequest {
    toLanguageCode: string;
    content: string;
    requestType: TransactionLogRequestType;
    transcriptionTransactionLogId: number | null;
    transcriptionFileName: string | null;
}

export interface ITextTranslationResponse {
    success: boolean;
    translatedText?: string;
    sourceLanguage?: string;
    transactionContext: ITransactionContext;
    errorMessage?: string;
}

export interface IDocumentStartDocumentTranslationResponse {
    success: boolean;
    id?: string;
    resultsUri?: string;
    errorMessage?: string;
    transactionContext: ITransactionContext;
}

export interface IDocumentStartTextExtractTranslationResponse {
    success: boolean;
    id?: string;
    uploadedFileUri?: string;
    errorMessage?: string;
    transactionContext: ITransactionContext;
    fileName: string;
    isImage: boolean;
}

export interface IDocumentTranslationRequestPropertiesModel {
    id: string;
    resultsUri: string;
    transactionLogId: number;
}

export interface IDocumentTranslationStatusRequestModel {
    requests: IDocumentTranslationRequestPropertiesModel[];
}

export interface IDocumentTextExtractionRequestPropertiesModel {
    id: string;
    transactionLogId: number;
    fileName: string;
    translateToLanguageCode: string;
}

export interface IDocumentTextExtractionStatusRequestModel {
    requests: IDocumentTextExtractionRequestPropertiesModel[];
}

export enum TranslationStatusType {
    Queued,
    Running,
    Success,
    Failed,
}

export interface IDocumentTranslationStatus {
    id: string;
    statusType: TranslationStatusType;
    resultsSasUri?: string;
    errorMessage?: string;
}

export interface IDocumentTranslationStatusResponseModel {
    statuses: IDocumentTranslationStatus[];
}

export interface DocumentTextExtractResultModel {
    pages: DocumentPageModel[];
    friendlyLanguage?: string;
    singleLanguageDetected: boolean;
}

export interface IDocumentTextExtractStatus {
    id: string;
    statusType: TranslationStatusType;
    result?: DocumentTextExtractResultModel;
    errorMessage?: string;
}

export interface IDocumentTextExtractionStatusResponse {
    statuses: IDocumentTextExtractStatus[];
}

export interface IDocumentTextExtractCapabilities {
    maxBytes: number;
    maxFileSizeDisplay: string;
    fileExtensions: string[];
}

export interface ITranslateState {
    responses: IIndexable<ITranslationResponse>;
    supportedLanguages: ISupportedLanguage[];
    supportedDocumentExtensions: string[];
    documentTextExtractCapabilities: IDocumentTextExtractCapabilities;
    selectedLanguageCode: string;
    translationType: TranslationType;
    translateText: string;
    pages: DocumentPageModel[];
    files?: FileList;
    isTranslatingText: boolean;
    defaultLanguageCode: string;
    activeDocumentTranslationUploads: IUploadInformation[];
    isPollingDocumentTranslation: boolean;
    activeDocumentTextExtractionUploads: IUploadInformation[];
    isPollingDocumentTextExtraction: boolean;
}

export type TSetResponses = (responses: IIndexable<ITranslationResponse>) => void;
export type TSetLanguages = (languages: ISupportedLanguage[]) => void;
export type TSetDefaultLanguageCode = (defaultLanguageCode: string) => void;
export type TSetDocumentExtensions = (documentExtensions: string[]) => void;
export type TSetTextExtractCapabilities = (textExtractCapabilities: IDocumentTextExtractCapabilities) => void;
export type TSetSelectedLanguage = (selectedLanguageCode: string) => void;
export type TSetTranslationType = (translationType: TranslationType) => void;
export type TSetTranslateText = (translateText: string) => void;
export type TSetFiles = (selectedFiles?: FileList) => void;
export type TSetIsTranslatingText = (isTranslatingText: boolean) => void;
export type TSetActiveDocumentTranslationUploads = (activeDocumentTranslationUploads: IUploadInformation[]) => void;
export type TSetIsPollingDocumentTranslation = (isPollingDocumentTranslation: boolean) => void;
export type TSetActiveDocumentTextExtractionUploads = (activeDocumentTextExtractionUploads: IUploadInformation[]) => void;
export type TSetIsPollingDocumentTextExtraction = (isPollingDocumentTextExtraction: boolean) => void;
export type TTransactionReviewed = (helpfulnessRating: TransactionLogHelpfulnessRatingType, responseName: string) => void;
export type TLoadLanguages = () => Promise<void>;
export type TLoadDocumentExtensions = () => Promise<void>;
export type TLoadTextExtractCapabilities = () => Promise<void>;
export type TGetAcceptedMimes = () => string[];
export type TGetAcceptedExtensions = () => string[];
export type TTranslateTextAction = () => Promise<void>;
export type TUploadFiles = (uploadStore: IUploadStore) => void;
export type TLanguageCodeDisplay = (languageCode: string | null) => string | null;
export type TRemoveResponse = (responseKey: string) => void;
export type TReset = () => void;

export interface ITranslateStore {
    state: DeepReadonly<ITranslateState>;
    setResponses: TSetResponses;
    setLanguages: TSetLanguages;
    setDefaultLanguageCode: TSetDefaultLanguageCode;
    setDocumentExtensions: TSetDocumentExtensions;
    setTextExtractCapabilities: TSetTextExtractCapabilities;
    setSelectedLanguage: TSetSelectedLanguage;
    setTranslationType: TSetTranslationType;
    setTranslateText: TSetTranslateText;
    setFiles: TSetFiles;
    setIsTranslatingText: TSetIsTranslatingText;
    setActiveDocumentTranslationUploads: TSetActiveDocumentTranslationUploads;
    setIsPollingDocumentTranslation: TSetIsPollingDocumentTranslation;
    setActiveDocumentTextExtractionUploads: TSetActiveDocumentTextExtractionUploads;
    setIsPollingDocumentTextExtraction: TSetIsPollingDocumentTextExtraction;
    transactionReviewed: TTransactionReviewed;
    loadLanguages: TLoadLanguages;
    loadDocumentExtensions: TLoadDocumentExtensions;
    loadTextExtractCapabilities: TLoadTextExtractCapabilities;
    getAcceptedMimes: TGetAcceptedMimes;
    getAcceptedExtensions: TGetAcceptedExtensions;
    translateTextAction: TTranslateTextAction;
    uploadFiles: TUploadFiles;
    languageCodeDisplay: TLanguageCodeDisplay;
    removeResponse: TRemoveResponse;
    reset: TReset;
    readonly noSelectedLanguage: boolean;
    readonly noTranslateText: boolean;
    readonly noFiles: boolean;
}
