// import {FORMAT_DATE, FORMAT_TIME} from '@/utils/dateTime';
// import moment from 'moment';
import { IFieldValidationContext } from '@/validation';

export type TValidationRule<T, V> = (context: IFieldValidationContext<T, V | null>) => Promise<true | string>;

export const stringRequiredRule = getValueRequiredRule<string>((value) => value !== null && value.trim().length > 0);
export const numberRequiredRule = getValueRequiredRule<number>();

export function getValueRequiredRule<V> (
  check?: (value: V | null) => boolean)
      : (errorMessage?: string) => TValidationRule<any, V> {
  const checker = check ?? ((value) => value !== null);
  return (errorMessage?: string) => {
    const errorMsg = errorMessage ?? 'Please enter a value.';
    return async ({ value }: IFieldValidationContext<any, V | null>) => checker(value) || errorMsg;
  };
}

// Tested this here: https://regexr.com/5scv9 so I know it's good.
const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export function emailFormatRule (message?: string): TValidationRule<any, string> {
  return async ({ value }: IFieldValidationContext<any, string | null>) =>
    value === null || emailRegex.test(value.toLowerCase()) || (message ?? `${value} is not a valid email.`);
}

export interface IPasswordValidationOptions {
  length?: number;
  containsNumber?: true;
  containsUpper?: true;
  containsLower?: true;
  containsSymbol?: true;
}

const digitRegex = /[0-9]/;
const symbolRegex = /[^a-zA-Z\d\s:]/;
const upperRegex = /[A-Z]/;
const lowerRegex = /[a-z]/;

export function passwordRequirements (options: IPasswordValidationOptions, fieldName = 'Password'): TValidationRule<any, string> {
  return async ({ value }: IFieldValidationContext<any, string | null>) => {
    if (value === null) {
      // We don't check password required here. use stringRequiredRule for that.
      return true;
    }

    if (options.length !== undefined) {
      if (value.length < options.length) {
        return `${fieldName} must be at least ${options.length} characters.`;
      }
    }

    if (options.containsNumber) {
      if (!digitRegex.test(value)) {
        return `${fieldName} should contain at least one number.`;
      }
    }

    if (options.containsLower) {
      if (!lowerRegex.test(value)) {
        return `${fieldName} should contain at least lowercase letter.`;
      }
    }

    if (options.containsUpper) {
      if (!upperRegex.test(value)) {
        return `${fieldName} should contain at least uppercase letter.`;
      }
    }

    if (options.containsSymbol) {
      if (!symbolRegex.test(value)) {
        return `${fieldName} should contain at least one symbol (#,$,%,^,&, etc).`;
      }
    }

    return true;
  };
}

/*
    For now, we don't have a need for validating dates.
    When we do, we can add moment as a dependency and
    uncomment the following code to get some common validator rules.
*/

// export const dateFormatRule = getDateFormatRule();
// export const timeFormatRule = getTimeFormatRule();

// export const dateRequiredRule: (errorMessage?: string)
//     => (context: IFieldValidationContext<string | Date | null>)
//         => Promise<true | string> = (errorMessage?: string) => {
//           const errMsg = errorMessage ?? `Date ${FORMAT_DATE.toLowerCase()} is required.`;
//           return getValueRequiredRule<string | Date>(
//             (value) => value !== null && (value instanceof Date || value !== ''))(errMsg);
//         };

// export function getDateFormatRule (
//   dateFormat?: string)
//     : (errorMessage?: string) => (context: IFieldValidationContext<string | Date | null>) => Promise<true | string> {
//   const format = dateFormat ?? FORMAT_DATE;
//   return (errorMessage?: string) => {
//     const errorMsg = errorMessage ?? `Date invalid format ${format.toLowerCase()}.`;
//     return async ({ value }: IFieldValidationContext<string | Date | null>) => !value ||
//             value instanceof Date ||
//             (value.length === format.length && moment(value, format).isValid()) ||
//             errorMsg;
//   };
// }

// export function getTimeFormatRule (
//   timeFormat?: string)
//     : (errorMessage?: string) => (context: IFieldValidationContext<string | null>) => Promise<true | string> {
//   const format = timeFormat ?? FORMAT_TIME;
//   return (errorMessage?: string) => {
//     const errorMsg = errorMessage ?? `Time invalid format ${format.toLowerCase()}.`;
//     return async ({ value }: IFieldValidationContext<string | null>) => !value ||
//             moment(`01/01/1979 ${value.toUpperCase()}`, `MM/DD/YYYY ${format}`).isValid() ||
//             errorMsg;
//   };
// }
