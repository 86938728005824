import { impl } from '@/utils/impl';
import { App, inject, provide, reactive, readonly } from 'vue';
import {
  IUserRegisterResponseModel,
  IUserRegisterStore,
  IUserRegisterState,
  TSubmit,
  TSetEmail,
  TSetFirstName,
  TSetLastName,
  TSetModel,
  TSetDialogState,
  UserRegisterDialogState,
  UserRegisterStatusTypeModel
} from '@/store/contracts/users/register';
import validationModel, { UserRegisterModelFieldName } from '@/validation/userRegisterValidationModel';
import { store as validationStore } from '@/store/validation';
import { sendRequest } from '@/store/utils/axiosUtils';
import {
  handleErrorStatus,
  IStatusHandler
} from '@/store/utils/apiResponseHandling';
import { IIndexable } from '@/utils/indexable';

const UserRegisterStoreKey = Symbol('UserRegisterStore');

const createState = () => {
  validationStore.clear(validationModel);
  return reactive(impl<IUserRegisterState>({
    model: {
      email: null,
      firstName: null,
      lastName: null
    },
    dialogState: UserRegisterDialogState.Inputting
  }));
};

function setEmail (state: IUserRegisterState): TSetEmail {
  return (email) => {
    state.model.email = email;
  };
}

function setModel (state: IUserRegisterState): TSetModel {
  return (model) => {
    state.model = model;
  };
}

function setFirstName (state: IUserRegisterState): TSetFirstName {
  return (firstName) => {
    state.model.firstName = firstName;
  };
}

function setLastName (state: IUserRegisterState): TSetLastName {
  return (lastName) => {
    state.model.lastName = lastName;
  };
}

function setDialogState (state: IUserRegisterState): TSetDialogState {
  return (dialogState) => {
    state.dialogState = dialogState;
  };
}

const registerErrorPropToValidationProp: IIndexable<string> = {
  LastName: UserRegisterModelFieldName.LastName,
  FirstName: UserRegisterModelFieldName.FirstName,
  Email: UserRegisterModelFieldName.Email
};

function submit (state: IUserRegisterState): TSubmit {
  return async () => {
    setDialogState(state)(UserRegisterDialogState.Loading);

    const valid = await validationStore.doValidation({
      model: validationModel,
      value: state.model
    });

    if (!valid) {
      setDialogState(state)(UserRegisterDialogState.Inputting);
      return;
    }

    const handler = impl<Partial<IStatusHandler<IUserRegisterResponseModel>>>({
      onError: async (r) => {
        const status = r?.status ?? 500;
        if (status !== 500) {
          const data = r?.data ?? {};
          let unhandled = false;
          Object.keys(data).forEach((errKey) => {
            const vProp = registerErrorPropToValidationProp[errKey];
            if (vProp) {
              validationStore.setModelFieldResult(validationModel.modelName, vProp, data[errKey].join('\n'));
            } else {
              unhandled = true;
            }
          });
          if (!unhandled) {
            return;
          }
        }
        setDialogState(state)(UserRegisterDialogState.Error);
      },
      onSuccess: async (r) => {
        switch (r.status) {
          case UserRegisterStatusTypeModel.Success: {
            setDialogState(state)(UserRegisterDialogState.Success);
            break;
          }
          case UserRegisterStatusTypeModel.InvalidEmail: {
            setDialogState(state)(UserRegisterDialogState.InvalidEmail);
            break;
          }
          case UserRegisterStatusTypeModel.DuplicateEmail: {
            setDialogState(state)(UserRegisterDialogState.DuplicateEmail);
            break;
          }
          case UserRegisterStatusTypeModel.UnknownError: {
            setDialogState(state)(UserRegisterDialogState.Error);
            break;
          }
        }
      }
    });
    await handleErrorStatus(await sendRequest<IUserRegisterResponseModel>(
      '/api/v1/users/register',
      'post',
      state.model
    ), handler);
  };
}

const createForState = (state: IUserRegisterState) => impl<IUserRegisterStore>({
  state: readonly(state),
  setModel: setModel(state),
  setEmail: setEmail(state),
  setFirstName: setFirstName(state),
  setLastName: setLastName(state),
  setDialogState: setDialogState(state),
  submit: submit(state)
});

export function provideStore (app?: App<Element>): void {
  const state = createState();
  if (app !== undefined) {
    app.provide(UserRegisterStoreKey, state);
  } else {
    provide(UserRegisterStoreKey, state);
  }
}

export function useStore (): IUserRegisterStore {
  const state = inject<IUserRegisterState>(UserRegisterStoreKey);
  if (state === undefined) {
    throw new Error('Using UserRegisterStore before providing it!');
  }
  return createForState(state);
}
